import * as React from 'react';
//import SvgIcon from '@mui/material/SvgIcon';
import "./NavBar.css";
import { Link } from 'react-router-dom';

/* function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  } */

export const Navbar = () => {

  const abrirEnNuevaPestana = () => {
    
    let link = localStorage.getItem("System_Link");
    window.open(link, '_blank');
  };
  
  let rol = localStorage.getItem("Rol");
  let isUser = localStorage.getItem("System_id");
  
  return (
    <div>
      {isUser === null ? 
        
        <div id="main-navbar" className="navbar">
          <img src={require("../imgs/nunah30.png")} style={{paddingTop:"80px"}} className="nunah15" alt="logo" />
          {rol==="Admin" || rol==="admin" ?  
          <nav>
            <ul>
              <li>
                  <Link to="/systemSelector">Lista de Sistemas</Link> 
                  <span className="separador">|</span>              
              </li>
              <li>
                  <Link to="/listUsers">Usuarios</Link>        
              </li>
            </ul>
          </nav> 
          :null}         
        </div>
        
      :
      <div id="main-navbar" className="navbar">
        <img src={require("../imgs/nunah30.png")} style={{paddingTop:"80px"}} className="nunah15" alt="logo" />
        <nav>
          <ul>
            <li>
              <a href="#"><Link to="/dashboard">Vista Global</Link></a><span className="separador">|</span>
            </li>
            {rol==="Admin" || rol==="admin" ? 
              <li>
                <Link to="/systemSelector">Lista de Sistemas</Link>
                <span className="separador">|</span>                
              </li>  
            : null}
            <li>
            <Link href="#" onClick={abrirEnNuevaPestana}>Documentación</Link>
            <span className="separador">|</span>
            </li>
            
            <li>
              <Link to="/charts">Gráficos</Link> 
              <span className="separador">|</span>              
            </li>   

            <li>
              <Link to="/alarmList">Alarmas</Link>               
            </li>            
            
          </ul>
        </nav>
      </div>
      }
       
    </div>
  );
};
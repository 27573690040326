import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeadMapPage from "./heatmap/HeadMapPage";
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

export default function SystemSelector(){

  const navigate = useNavigate();

  useEffect(() => {
    let token= localStorage.getItem("Token");
    let tokenExp= localStorage.getItem("TokenExp");
    let vencimiento = new Date(tokenExp); 
    let fechaactual = new Date(); 

    if(token==null || fechaactual > vencimiento){
      navigate("/login");
    }
    
  }, []);

  localStorage.removeItem("System_id");
  localStorage.removeItem("System_Name");
  localStorage.removeItem("System_Mantenimiento");
    
  return (
    <div>
      <SiteHeader/>
      <HeadMapPage/>
      <SiteFooter/>
    </div>    
  );
}

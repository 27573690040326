import React, { useState, useEffect  } from "react";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as moment from 'moment';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function Navicons() { 
  let isUser = localStorage.getItem("System_id");
  let rol = localStorage.getItem("Rol");
  let [dialogMessageOpen, setDialogMessageOpen] = useState(false); 
  let [message] = useState('¿Desea cerrar sesión?');
  const navigate = useNavigate();
  useEffect(() => {
    if(isUser != null){
      getAlarmData();
    }
  }, [isUser]);
  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 16,
      top: 13,
      border: `2px solid #df6e53`,
      padding: '0 4px'
    },
  }));
  
  const count = Number(localStorage.getItem("CountAlarm"));
  const systemId = localStorage.getItem("System_id");
  const [data, setData] = useState([]);

  const signOut = (e) => {
    setDialogMessageOpen(true);
  };

  const handleCancelSignOut= () => {
    setDialogMessageOpen(false);
  };
  
  const handleConfirmSignOut = () =>{  
    localStorage.removeItem("System_id");
    localStorage.removeItem("Email");
    localStorage.removeItem("Token");
    localStorage.removeItem("TokenExp");
    localStorage.removeItem("Rol");
    localStorage.removeItem("CountAlarm");
    localStorage.removeItem("Record_id");

    navigate("/home");
  }

  const getAlarmData = () =>{  
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const systemid = localStorage.getItem("System_id");
      const rol = localStorage.getItem("Rol");
      const tipoUser = rol==='Admin'? 1 : 2;
      const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            SystemId: systemid,
            TipoUsuario: Number(tipoUser)
          })
        };
        var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/GetAlarmDataByClient", requestOptions);
        
        fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            
            if(data.objectResponse != null){
              if(data.objectResponse.data != null){
                setData(data.objectResponse.data);
              }             
              localStorage.setItem("CountAlarm", data.objectResponse.count);
              localStorage.setItem("Record_id", data.objectResponse.id);
            }        
            else
            {
              localStorage.setItem("CountAlarm", "0");
              localStorage.setItem("Record_id", " ");
            }    
        });
    }
    
    return (


      <div>
        
        <div className="nav-icon">
          {isUser === null ? 
            <div style={{paddingTop:"50px"}}>
              {rol==="Admin" || rol==="admin" ? 
                <Link to="/heatmap/newsystem">
                    <Tooltip title="Agregar Sistema" gear>
                    <IconButton aria-label="Example"> 
                        <FontAwesomeIcon icon={solid("file-circle-plus")} style={{color: "#ffffff",}} />
                    </IconButton>
                    </Tooltip>
                </Link>
               :null} 
              <Button onClick={signOut}>
                <Tooltip title="Cerrar Sessión" user>
                  <IconButton aria-label="Example"> 
                      <FontAwesomeIcon icon={solid("user")} style={{color: "#ffffff",}} />
                  </IconButton>
                </Tooltip>
              </Button> 
            </div>
          :
            <div>
              <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                  <div className="btn-alarm">
                      <Button {...bindTrigger(popupState)}>
                        <Tooltip title="Alarmas" bell>
                          <StyledBadge badgeContent={count} color="secondary">
                              <IconButton aria-label="Example"> 
                                  <FontAwesomeIcon icon={solid("bell")} style={{color: "#ffffff",}} />
                              </IconButton>
                            </StyledBadge>
                          </Tooltip>
                      </Button>
                      <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                      }}
                      transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                      }}
                      >
                       <Typography sx={{ p: 2 }}> 
                          <List sx={{ pt: 0 }}>
                          <h2>Lista de Alarmas</h2>
                          {data.map((alarm) => (
                              <ListItem disableGutters key={alarm.text}>
                              <ListItemButton>
                                  <ListItemText primary={moment(alarm.logDateTime).format("DD/MM/YYYY hh:mm A") + " " + alarm.text} />
                              </ListItemButton>
                              </ListItem>
                          ))}
                          </List>
                      </Typography>
                      </Popover>
                  </div>
              )}
              </PopupState>
              <div style={{paddingTop:"10px"}}>

                <Link to={`/heatmap/editsystem/${systemId}`}>
                  <Tooltip title="Configuración" gear>
                    <IconButton aria-label="Example"> 
                      <FontAwesomeIcon icon={solid("gear")} style={{color: "#ffffff",}} />
                    </IconButton>
                  </Tooltip>
                </Link>

                <Link to="/Login" style={{paddingLeft:"10px"}}> 
                  <Button onClick={signOut}>
                    <Tooltip title="Cerrar Sessión" user>
                      <IconButton aria-label="Example"> 
                        <FontAwesomeIcon icon={solid("user")} style={{color: "#ffffff",}} />
                      </IconButton>
                    </Tooltip>
                  </Button>                  
                </Link>
              </div>
            </div>
          }
          
      </div>
      <div>
          <Dialog open={dialogMessageOpen} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Confirmar</DialogTitle>
              <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmSignOut} color="primary">
                  Aceptar
                </Button>
                <Button onClick={handleCancelSignOut} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
        </div>
      </div>
      
    );
}
import React, { useState, useEffect } from "react";
import "./ListaClientes.css";
import { useNavigate } from 'react-router-dom';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from '@mui/material/Button';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import CustomDialog from "../Generals/CustomDialog";

export default function SystemsTable2(){

  const navigate = useNavigate();
  const rol = localStorage.getItem("Rol");
  const email = localStorage.getItem("Email");
  let [dialogMessage, setDialogMessage] = useState(false); 
  let [message] = useState('Desea eliminar este registro?');
  let [selectedSystem, setSelectedSystem] = useState('');
  let [selectedIdSystem, setSelectedIdSystem] = useState('');  
  let [dialogMessageConfirm, setDialogMessageConfirm] = useState(false); 
  let [messageOkConfirm, setMessageOkConfirm] = useState('');
  let [rows, setRows] = useState([]);
  let [columns] = useState([
      { field: 'documentInfo.status',
        headerName: 'Status',
        minWidth: 20, 
        width: 70, 
        renderCell: (params) => { return params.row.documentInfo.status ==='Active' ?        
                                    <div className="icon-with-text" data-text="En línea">
                                    <FontAwesomeIcon icon={solid("circle-check")} size="xl" style={{color: "#50B09E",}}/> </div> 
                                  : params.row.documentInfo.status ==='Inactive' ? 
                                    <div className="icon-with-text" data-text="Fuera de línea">
                                    <FontAwesomeIcon icon={solid("circle-xmark")} size="xl" style={{color: "#e90c0c",}}/> </div>
                                  : <div className="icon-with-text" data-text="Parcialmente en línea">
                                    <FontAwesomeIcon icon={solid("triangle-exclamation")} size="xl" style={{color: "#ec7636",}}/> </div> ; }
      },
      { field: 'documentInfo.nombre', headerName: 'Nombre',  
      valueGetter: (params) => params.row.documentInfo.nombre,
      minWidth: 200 },
      { field: 'alarms', headerName: '# Alarmas',  minWidth: 200 },
      { field: 'generacion', headerName: 'Generacion',  minWidth: 200 , renderCell: (params) => {
        return (<p>{params.row.generacion} %</p>);
      }},
      { field: 'ahorro', headerName: 'Ahorro',  minWidth: 200, renderCell: (params) => {
         return (<p>₡ {params.row.ahorro.toLocaleString()}</p>);
       }},
       {
        field: "action",
        headerName: "Opciones",
        sortable: false,
        width: 170,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
            <div style={{ cursor: "pointer"}}>
              <button data-index={params.row.id} className="buttonSD"  type="button" onClick={() =>handleClickInactOpen(params.row.id, params.row.documentInfo.systemId)}>
                <FontAwesomeIcon icon={solid("trash-can")} size="xl"/>
              </button>
            </div>                  
          );
        }
      }
  ]);

  useEffect(() => {
    if (rol === 'user') {
      getSystemsByUser();
    } else {
      getSystems();
    }
  }, []);

  const handleClickInactOpen = (id, systemId) => {
    setDialogMessage(true);
    setSelectedSystem(systemId);
    setSelectedIdSystem(id);
  }

  const handleConfirmDelete= () => {
    deleteSystem();
    setDialogMessage(false);
    setSelectedSystem('');
  }

  const handleOkConfirm= () => {
    setDialogMessageConfirm(false);
    setSelectedSystem('');
    setSelectedIdSystem('');
  }

  const deleteSystem = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Id: selectedIdSystem,
        SystemId : selectedSystem
      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/DeleteSystemById", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.successful){
          setMessageOkConfirm("Sistema eliminado correctamente.");
          setDialogMessageConfirm(true);
          getSystems();

        }else{
          setMessageOkConfirm("Ha ocurrido un error al eliminar el sistema.");
          setDialogMessageConfirm(true);
        }                       
      });  
  }

  const handleCancelDelete= () => {
    setDialogMessage(false);
  }

  const getSystemsByUser = () => { 
    setRows([]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: email
      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/GetSystemListByUser", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {        
        setRows(data.objectResponse); 
      });  
  }

  const getSystems = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    setRows([]);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({

      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/GetSystemList", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        setRows(data.objectResponse); 
        /* if (data.objectResponse != null) {
          var miArray = [];
          for (var clave in data.objectResponse) {
            if (data.objectResponse.hasOwnProperty(clave)) {                  
              miArray.push(data.objectResponse[clave]);
            }
          }
          console.log(miArray);
          setRows(miArray); 
        }*/
      });  
  }

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const handleRowClick = (params) => {
    if (params.field !== 'action') {
      clearActualSystemData();

      const system = params.row.documentInfo.systemId;
      const name = params.row.documentInfo.nombre;
      const plan = params.row.documentInfo.planMantenimiento;
      const enlace = params.row.documentInfo.enlace;
      const lat = params.row.documentInfo.coordenadaLat;
      const lng = params.row.documentInfo.coordenadaLng;
      const status = params.row.documentInfo.status;

      localStorage.setItem("System_id", system);
      localStorage.setItem("System_Name", name);
      localStorage.setItem("System_Mantenimiento", plan);
      localStorage.setItem("System_Link", enlace);
      localStorage.setItem("Coord_lat", lat);
      localStorage.setItem("Coord_lng", lng);
      localStorage.setItem("System_Status", status);

      navigate("/dashboard");
    }
  };

  const clearActualSystemData = () =>{
    localStorage.removeItem("System_id");
    localStorage.removeItem("System_Name");
    localStorage.removeItem("System_Mantenimiento");
  }

  return (
    <div>        
      <style>
        {`
          .MuiDataGrid-row:hover {
            cursor: pointer;
          }
        `}
      </style>
        <DataGrid
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns} 
          checkboxSelection = {false}
          pageSize={5}
          getRowClassName={getRowClassName}
          onCellClick={handleRowClick}
          //onRowClick={handleRowClick}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps:false,              
            },
          }}                
          disableRowSelectionOnClick
        />

        <Dialog open={dialogMessage} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Información</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
                <Button onClick={handleConfirmDelete} color="primary">
                  Aceptar
                </Button>
                <Button onClick={handleCancelDelete} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
        </Dialog>

        <CustomDialog
          open={dialogMessageConfirm}
          title="Información"
          message= {messageOkConfirm}
          handleConfirm={handleOkConfirm}
        />
    </div>
  );

}
import React, { useState, useEffect } from "react";
import HeatmapTest from './HeatmapTest';
import SystemsTable from './SystemsTable';
import "./ListaClientes.css";
import IconButton from '@mui/material/IconButton';
import {Button, Grid, Paper} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function HeadMapPage() {

  const [totalSystems, setTotalSystems] = useState(null);
  const [activeSystems, setActiveSystems] = useState(null);
  const [totalGeneration, setTotalGeneration] = useState(null);
  const [power, setPower] = useState(null);
  const [ahorro, setAhorro] = useState(null);
  const rol = localStorage.getItem("Rol");

  useEffect(() => {
    if(rol !== 'user')
      getIndicators();
  }, [rol]);

  const getIndicators = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/GetSystemSelectorIndicators", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if (data.objectResponse != null) {
          setTotalSystems(data.objectResponse.totalSystems);
          setActiveSystems(data.objectResponse.activeSystems);
          setTotalGeneration(data.objectResponse.totalGeneration);
          setPower(data.objectResponse.power);
          setAhorro(data.objectResponse.ahorro.toLocaleString());
        }
      });  
  }

  const countSystems = () => {
    return (
      <Grid item container>
        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">
            
            <Grid item xs={3} sm={3}>
              <Button>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>                  
            </Grid>
            <Grid item xs={9} sm={6} >
              <span className='graph-text'>Instalaciones</span>
            </Grid>
            <Grid item xs={9} sm={3} ></Grid>              
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">              
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={9} sm={9} >
              <h1 className='graph-Body'>{activeSystems}/{totalSystems}</h1>
              <span className='graph-text'>Conectado</span>
            </Grid>                
          </Grid>              
        </Grid>            
      </Grid>
    );
  }

  const potencia = () => {
    return (
      <Grid item container>
        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">
            
            <Grid item xs={3} sm={3}>
              <Button>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>                  
            </Grid>
            <Grid item xs={9} sm={6} >
              <span className='graph-text'>Potencia</span>
            </Grid>
            <Grid item xs={9} sm={3} ></Grid>              
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">              
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={9} sm={9} >
              <h1 className='graph-Body'>{power}</h1>
              <span className='graph-text'>kW</span>
            </Grid>                
          </Grid>              
        </Grid>            
      </Grid>
    );
  }

  const generacion = () => {
    return (
      <Grid item container>
        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">
            
            <Grid item xs={3} sm={3}>
              <Button>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>                  
            </Grid>
            <Grid item xs={9} sm={6} >
              <span className='graph-text'>Generación Total</span>
            </Grid>
            <Grid item xs={9} sm={3} ></Grid>              
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">              
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={9} sm={9} >
              <h1 className='graph-Body'>{totalGeneration}</h1>
              <span className='graph-text'>kWh</span>
            </Grid>                
          </Grid>              
        </Grid>            
      </Grid>
    );
  }

  const iAhorro = () => {
    return (
      <Grid item container>
        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">
            
            <Grid item xs={3} sm={3}>
              <Button>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>                  
            </Grid>
            <Grid item xs={9} sm={6} >
              <span className='graph-text'>Ahorro Total</span>
            </Grid>
            <Grid item xs={9} sm={3} ></Grid>              
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item container spacing={2} alignItems="center">              
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={9} sm={9} >
              <h1 className='graph-Body'>₡ {ahorro}</h1>
            </Grid>                
          </Grid>              
        </Grid>            
      </Grid>
    );
  }

  return (
    <div>
      <Grid container spacing={2} justify="center">  
        {rol !== 'user' ? 
          
             
        <Grid style={{paddingLeft:'50px',paddingRight:'34px' }} item container direction="row" xs={12} sm={12} spacing={'16px'}>
          <Grid item xs={5} sm={10}>
            <Paper style={{ height: "600px" }} >
              <HeatmapTest></HeatmapTest>
            </Paper>
          </Grid>
          <Grid item container direction="column" xs={2} sm={2} spacing={'16px'}>
            <Grid item>
              <Paper style={{ height: "139px", background: "#363A45" }}>
                  {countSystems()}
              </Paper>
            </Grid>
            <Grid item>
              <Paper style={{ height: "139px", background: "#363A45" }}>
                {potencia()}
              </Paper>
            </Grid>
            <Grid item>
              <Paper style={{ height: "139px", background: "#363A45" }}>
                {generacion()}
              </Paper>
            </Grid>
            <Grid item>
              <Paper style={{ height: "139px", background: "#363A45" }}>
                {iAhorro()}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        : null}
        {/* Grid Sistemas */}
        <Grid item xs={6} sm={12}>
            <div className="grid-Clientes">              
              <SystemsTable/>
            </div>
         </Grid>

      </Grid>
    </div>
  );
}

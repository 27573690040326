import * as React from 'react';
import {Button, Grid, Paper, Popover, Typography} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import '../charts/Graphics.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    textAlign: 'center'
  
  }));

export default class Generacion extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {    
      alert:false,
      alertContent:"",
      anchor:null,
      generationPorc:'0.0',
      margen:''
    };
  };

  openPopover = (event) => {
    this.setState({anchor:event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchor:null});
  };

  componentDidMount() {
    this._isMounted = true;
    this.getGenerationData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getGenerationData(){    
    let structure = "GetGenerationIndicator";
    let system = localStorage.getItem("System_id");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: system
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Fronius/"+structure, requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.objectResponse != null){
          let generation = parseFloat(data.objectResponse.generation);
          if (generation < 0) {            
            generation = Math.abs(generation);
            this.setState({margen:'Inferior'});
          }else{
            this.setState({margen:'Superior'});
          }
          this.setState({generationPorc:generation});
        }
      });
    }

  render(){
    return (
      <React.Fragment>
        <div className='indicator-poliza'>  
          <Grid item container spacing={2}>
            <Grid className='central-indicators' item xs={12} sm={12}>
              <Grid item container spacing={2} alignItems="center">
                
                <Grid item xs={3} sm={3}>
                <Button onClick={this.openPopover}>
                  <IconButton aria-label="Example"> 
                    <FontAwesomeIcon icon={solid("circle-info")} inverse />
                  </IconButton>
                </Button>
                <Popover 
                  open={Boolean(this.state.anchor)} 
                  anchorEl={this.state.anchor}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}>
                  <Typography style={{padding:'5px'}} variant="h6">
                    Comparativa entre la generación <br/>
                    de energía real y el valor teórico.
                  </Typography>
                </Popover>
                </Grid>
                <Grid item xs={9} sm={6} >
                  <span className='graph-text'>Generación Mensual</span>
                </Grid>
                <Grid item xs={9} sm={3} >
                  <Item sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
                    <IconButton aria-label="Example"> 
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")} inverse />
                    </IconButton>
                  </Item>
                </Grid>
              
              </Grid>
            </Grid>

            <Grid className='central-indicators' item xs={12} sm={12}>

              <Grid  item container spacing={2}  alignItems="center">
              
                <Grid item xs={3} sm={3}>
                  <div  style={{paddingLeft:"15px"}}>

                    <Button variant="contained" disabled>
                      <FontAwesomeIcon icon={solid("helmet-safety")} size="xl" style={{color: "#50B09E",}} />
                    </Button>

                  </div>
                  
                </Grid>
                <Grid item xs={9} sm={9} >
                  <h1 className='graph-Body'>{this.state.generationPorc}%</h1>
                  <span className='indicator-mess'> {this.state.margen} a lo esperado</span>

                  
                  
                  
                </Grid>                
              </Grid>              
            </Grid>
          </Grid>

        </div>
        
      </React.Fragment> 
    );
  }
  
}

import React, { useEffect, useState } from 'react';
import './Graphics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import IconButton from '@mui/material/IconButton';
import LineaDerecha from './LineaDerecha';
import LineaIzquierda from './LineaIzquierda';
import LineaIzquierdaOff from './LineaIzquierdaOff';
import LineaIzquierdaReverse from './LineaIzquierdaReverse';
import {Button, Grid, Popover, Typography} from '@mui/material';

function EnergyFlow () {

  const [sol, setSol] = useState(null);
  const [antena, setAntena] = useState(null);
  const [consumo, setConsumo] = useState(null);
  const [systemName] = useState(localStorage.getItem("System_Name"));
  const [anchor, setAnchor] = useState(null);
  const [active, setActive] = useState(0);
  const [systemStatus] = useState(localStorage.getItem("System_Status"));
  
  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const fetchData = async () => {
    try {
      let system = localStorage.getItem("System_id");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          InformationType: '',
          SystemId: system,
          Type: 0,
          Day: '',
          Month: '',
          Year: ''
        })
      };
      var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/GetEnergyFlowByClient", requestOptions);
      
      await fetch(myRequest).then(response => {
        if (!response.ok) {
          throw new Error('Error en la solicitud de datos');
        }
        return response.json();
      })
      .then(data => {
        setSol(data.objectResponse.powerPv);        
        setAntena(data.objectResponse.powerFeedIn);
        setConsumo(data.objectResponse.powerLoad);

        var sol = data.objectResponse.powerPv;
        var antena = data.objectResponse.powerFeedIn;
        var consumo = data.objectResponse.powerLoad;
        localStorage.setItem("PorcAutoS", data.objectResponse.autosuficiencia);

        if(sol < consumo && antena !== 0)
          setActive(1);
        else if(antena !== 0)      
          setActive(2);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, []);

    return (
      <React.Fragment>        
        <div>
          <Grid container justify="center">
            
            <Grid item xs={12} sm={12}>   
              <div className='ef-center-ele'>
                <h1 className='graph-Body'>{systemName}</h1>
                <div style={{paddingLeft:"10px", paddingBottom:"25px"}}>
                  { systemStatus ==='Active' ?        
                    <div class="icon-with-text" data-text="En línea">
                    <FontAwesomeIcon icon={solid("circle-check")} size="xl" style={{color: "#50B09E",}}/> </div> 
                  : systemStatus ==='Inactive' ? 
                    <div class="icon-with-text" data-text="Fuera de línea">
                    <FontAwesomeIcon icon={solid("circle-xmark")} size="xl" style={{color: "#e90c0c",}}/> </div>
                  : <div class="icon-with-text" data-text="Parcialmente en línea">
                    <FontAwesomeIcon icon={solid("triangle-exclamation")} size="xl" style={{color: "#ec7636",}}/> </div> }
                </div>
                
              </div>
              <div className='ef-center-ele'>            
              <Button onClick={openPopover}>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>
              <Popover 
              open={Boolean(anchor)} 
              anchorEl={anchor}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}>
                <Typography style={{padding:'5px'}} variant="h6">
                  Flujo de potencia en 
                  <br/>
                  tiempo real del sistema.                  
                  
                </Typography>
              </Popover>
                <span className='graph-text'>Información en Tiempo Real </span>  
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className='ef-center-ele'>
                <img src={require("../../imgs/Solar.png")} className="ef-img-solar" alt="logo" />
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>  
              <div className='ef-center-ele'>
                <span className='graph-text'>{sol} W</span> 
              </div> 
            </Grid>

            <Grid item xs={12} sm={12}>    
              <div className='ef-center-lines'>
                <div className="alto-lineas">
                  {sol === "0.0" ? <div className="linea-vertical"></div>  : <div className="linea-discontinua"></div> }
                </div>
              </div>  
            </Grid>

            <Grid item xs={12} sm={12}>  
              <div className='ef-center-spa'>
                <div>
                  <img src={require("../../imgs/2.png")} alt="logo" />            
                </div>
                <div className="full-lineas">
                  <div className="linea-fija"></div>
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <div className="blobs-container">
                    <div className="blob white"></div>
                  </div>                
                </div>
                <div className="full-lineas">
                  <div className="linea-fija"></div>
                </div>
                <div>
                  <img src={require("../../imgs/Generador.png")} alt="logo" />   
                </div>
              </div> 
            </Grid>

            <Grid item xs={12} sm={12}>  
              <div className='ef-center-ele'>
                <span className='graph-text'>{consumo} W</span> 
              </div> 
            </Grid>

            <Grid item xs={12} sm={12}>     
              <div className='ef-center-lines'>
                <div className="alto-lineas">
                  {active === 0 ? <LineaIzquierdaOff/> : active === 1 ? <LineaIzquierdaReverse/>: <LineaIzquierda/> }
                </div>
                <div className="alto-lineas">
                  <div className="linea-vertical"></div>
                </div> 
                <div className="alto-lineas">
                  <LineaDerecha/>                 
                </div>
              </div>                
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className='ef-center-spa'>
                <div>
                  <img src={require("../../imgs/Antenna.png")} className="ef-img"  alt="logo" />  
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <img src={require("../../imgs/Battery.png")} className="ef-img" alt="logo" />
                </div>
                <div style={{ alignSelf: 'flex-end' }}>
                  <img src={require("../../imgs/UPS.png")} className="ef-img"  alt="logo" />   
                </div>
              </div>   
            </Grid>

            <Grid item xs={12} sm={12}>  
              <div className='ef-center-spa'>
                <span className='graph-text'> {antena} W</span>
              </div> 
            </Grid>

          </Grid>
            
        </div>
      </React.Fragment> 
    );
  
}

export default EnergyFlow;
import React, { useState, useEffect } from "react";
import './NunaLandingPage.css';
import { Modal, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}; */

export default function NunaLandingPage(){

  const navigate = useNavigate();
  //const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loginParams, setLoginParams] = useState({
    user_id: "",
    user_password: ""
  });

  const handleOpenModal = event => {
    event.preventDefault();
    setModalOpen(true);
  };
    
  const handleFormChange = event => {
    let loginParamsNew = { ...loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    setLoginParams(loginParamsNew);
  };

  /* const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setModalOpen(false);
    }
  }; */

  /* const getSystemInformation = (systemid) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/GetSystemInformation", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              localStorage.setItem("System_Name", data.objectResponse.documentInfo.nombre);
              localStorage.setItem("System_Mantenimiento", data.objectResponse.documentInfo.planMantenimiento);
              localStorage.setItem("System_Link", data.objectResponse.documentInfo.enlace);
            }            
          });
  } */
    
  const handleLogin = (event) => {
    event.preventDefault();
    let user_id = loginParams.user_id;
    let user_password = loginParams.user_password;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Username: user_id,
        Password: user_password
      })
    };
    
    var myRequest = new Request( process.env.REACT_APP_API_URL + "/api/Login/Authenticate", requestOptions);
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.successful){
          if(data.codError === '00'){
            localStorage.setItem("Email", data.objectResponse.userRequest.email);
            localStorage.setItem("Rol", data.objectResponse.userRequest.role);
            localStorage.setItem("CountAlarm", "0");
            localStorage.setItem("Record_id", " ");
            localStorage.setItem("Token", data.objectResponse.token.token);
            localStorage.setItem("TokenExp", data.objectResponse.token.tokenExp);

            if(data.objectResponse.userRequest.role === 'admin'){
              navigate("/systemSelector");
            }else{
              //localStorage.setItem("System_id", data.objectResponse.userRequest.systemId);
              //getSystemInformation(data.objectResponse.userRequest.systemId);
              navigate("/systemSelector");
            }
          }
          else if(data.codError === '97'){
            console.log("error en los credenciales");
          }
          else if(data.codError === '98'){
            navigate("/home/resetPassword");
          }
        }else{
          console.log('Usuario no encontrado');
        }
        
      });

  };

  const handleClickDemo = () => {
    navigate("/home/demo");
  };

  const handleClickControl = () => {
    navigate("/home/control");
  };
    
  useEffect(() => {
      localStorage.removeItem("System_id");
      localStorage.removeItem("Email");
      localStorage.removeItem("Token");
      localStorage.removeItem("TokenExp");
      localStorage.removeItem("Rol");    
  }, []);

  return(
      
      <div>
        <div style={{zIndex:"2",position:"fixed", height:"200px", width:"100%"}}>
          <div className='top-superpuesto' style={{height:"156px"}}>  
            <div className='header-logo'>
              <a href="/home">
                <img src={require("../imgs/nunah30.png")} className="nunah35" alt="logo" />
              </a>
            </div>
            <div className='header-buttons'>
              <button className="buttonsStyle" onClick={handleOpenModal}>INICIAR SESIÓN</button>
              <input
                className="buttonsStyle"
                type="button"
                onClick={handleClickDemo}
                value="SOLICITAR DEMO" />
            </div>
          </div>
        </div>
        <div style={{position:"absolute", height:"850px", marginLeft:"50px"}}>
          <div className='' style={{height:"276px", width:"550px", paddingTop:"45%", paddingLeft:"10%"}}>  
            <div>
              <img src={require("../imgs/nunah30.png")} className="nunah35" alt="logo" />
            </div>
            <div className="linea-h"></div>
            <div className='generic-text-just-xl'>
            Nuestra tecnología evalúa el<br/>
            consumo energético, para la toma <br/> 
            de decisiones precisa en tiempo <br/>
            real, y la planificación estratégica<br/>             
            de la gestión eléctrica futura.<br/>
            <br/>
              <button onClick={handleClickControl} className="buttonSl">TOMÁ EL CONTROL AQUÍ</button>
            
            </div>
          </div>
        </div>
        
        <div className="contImg">
          <div className='back-img'></div>
        </div>
        
        
      <div className="intro-ext">
        <div className="intro-int">
          <div className='generic-title-center'>            
          <b>Eficiencia</b>
          </div>
          <br/>
          <div className='generic-text-center' style={{height:"90px"}}> 
            Con <img src={require("../imgs/nunah30.png")} className="nunah15" alt="logo" /> tenés acceso inmediato la información integral de tus recursos <br/>
            energéticos. Para que aprovechés el máximo el potencial de tu energía mientras <br/>
            ahorrás.
          </div>
          <br/><br/><br/>
          <div style={{display:'flex', alignItems:"center", justifyContent:"center"}}>
            <div className='intro-box'>
              
              <img src={require("../imgs/logo-config.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <h1 className='generic-subtitle-green'>Producción</h1><br/>
                Con datos del sistema podés <br/>
                confirmar el estado del <br/>
                consumo y tomar decisiones
            </div>
            <div className='intro-box'>                
              <img src={require("../imgs/logo-analisis.png")} className="logoConfig" alt="logo" />
              <br/><br/>
                <h1 className='generic-subtitle-green'>Análisis</h1><br/>
                Control absoluto de la <br/>
                energía: impacto ambiental, <br/>
                mantenimiento, ahorro actual <br/>
                y almacenamiento
            </div>
            <div className='intro-box'>                
              <img src={require("../imgs/logo-optimizacion.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <h1 className='generic-subtitle-green'>Optimización</h1><br/>
                Al visualizar el ahorro en <br/>
                tiempo real, podrás potenciar <br/>
                la eficiencia energética
            </div>
          </div>
          
        </div>
      </div>
      <div className="linea"></div>
      <div className='div2part' style={{height:"699px"}}>
        <div className="parte1">   
          <div style={{width:"400px", height:"500px"}}>
            <h1 className='generic-title-just'>Control</h1>
            
            <div className='generic-text-just'>
              A través de un sistema intuitivo,<br/>          
              <img src={require("../imgs/nunah30.png")} className="nunah15" alt="logo" /> te facilita entender y <br/>
              administrar la producción y <br/>
              consumo de energía en tu <br/>
              empresa y comercio.
              <br/><br/>
              Desde que ingresa a los <br/> 
              paneles solares, su paso a la <br/>
              red, el almacenamiento en <br/>
              baterías y más.
            </div>
          </div> 
        </div>
        <div className='happy-img parte2'></div>
      </div>
      <div className="linea"></div>
      <div className="highlights-ext">
        <div className="highlights-int">
          <h1 className='generic-title-center'>Beneficios</h1>

          <br/><br/>
          <br/><br/>

          <div>
            <div className='intro-box' style={{height:"200px",  width:"50%", float:"left"}}>
              <img src={require("../imgs/icon-bn4.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <span className='texto-destacado'>Impacto ambiental positivo</span> directo <br/>
              como consecuencia de la ejecución <br/>              
              del proyecto de energía limpia.
            </div>
            <div className='intro-box' style={{height:"200px",  width:"50%", float:"left"}}>
              <img src={require("../imgs/icon-bn3.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <span className='texto-destacado'>Indicadores de ahorro</span> de hasta un 40% <br/>
              menos en promedio, gracias al <br/>
              consumo de energía solar. Puede  <br/>
              variar según cada caso.
            </div>
          </div>

          <div>
            <div className='intro-box' style={{height:"200px",  width:"50%", float:"left"}}>
              <img src={require("../imgs/icon-bn2.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <span className='texto-destacado'>Generación mensual de energía limpia</span> <br/>
              con datos en tiempo real del sistema.
            </div>
            <div className='intro-box' style={{height:"200px",  width:"50%", float:"left"}}>
              <img src={require("../imgs/icon-bn1.png")} className="logoConfig" alt="logo" />
              <br/><br/>
              <span className='texto-destacado'>Eficiencia del consumo de energía</span> <br/>
              <span className='texto-destacado'>limpia</span>, pronóstico de la producción y <br/>
              almacenamiento del sistema.
            </div>
          </div>
        </div>
      </div>

      <div className="linea"></div>
      <div className='div2part' style={{height:"701px"}}>
        <div className='nuna-img parte2'></div>
        
        <div className="parte1">
          <div style={{width:"400px", height:"350px"}}>
            <h1 className='generic-title-just'>Libertad</h1>
            
            <div className='generic-text-just'>
            <img src={require("../imgs/nunah30.png")} className="nunah15" alt="logo" /> es el alma de la <br/>
            conexión de los recursos <br/> 
            energéticos de tu empresa, <br/>
            comercio, e industria.
            <br/><br/>
            Es la conciencia de todas las <br/>
            posibilidades que resultan de <br/>
            tener la libertad de generar y <br/>
            administrar tu propia energía, <br/>
            con tranquilidad y confianza.
            </div>
          </div>
      
        </div>          
      </div>
      <div className="linea"></div>

      <div className="alianzas-ext">
        <div className="alianzas-int">
          <div className='divImg'>
            <img src={require("../imgs/nunah30.png")} className="nunah25" alt="logo" />
            <h1 style={{marginLeft:"10px"}}> Comunidad</h1>
          </div>
          <br/>
          <div className='generic-text-center'>
            Acompañanos a revolucionar el consumo de energía, <br/>
            para un futuro más inteligente y sostenible. <br/>
            <br/>
            Seamos pioneros en una nueva era que satisfaga <br/>
            las necesidades actuales y proteja las del mañana. <br/>
            <br/>
            NUNA® es poder, es libertad, es el camino hacia una gestión energética consciente.
          </div>                      
          
        </div>
      </div>
      <div className="linea"></div>
      <div className='big-img' style={{height:"701px"}}></div>
      <div className="linea"></div>
      <div className="footer-ext">
        <div className="footer-int">
          <div className="" style={{width:"120px"}}>
            <img src={require("../imgs/nunah30.png")} className="nunah35" alt="logo" />
          </div>
          <div className="divText-top" style={{width:"954px"}}>
          <div className='footer-box'>                
            <h1 className='generic-title-footer'>Contacto</h1>
            <br/>
            <div className='generic-text-center'>
              Correo:
              <a className='texto-destacado2'  href="luise289@gmail.com"> info@nuna.cr</a>
                <br/>

              Teléfono: (506) 2290-7727<br/>
              Whatsapp: (506) 6395-5374
            </div>
            <br/><br/>
            San José, Costa Rica 2024. © Todos los derechos reservados. 
              <span className='texto-destacado2'>
                <a style={{textDecoration:"underline", color:"#5ebdd9"}} href="/home/terminos-y-condiciones" target="_blank" rel="noopener" id="terms">Términos y Condiciones de privacidad</a>               
              </span>
            </div>
            
          </div>
          <div className="" style={{width:"166px"}}>
            <img src={require("../imgs/icon-email.png")} className="iconsfooter" alt="logo" />
  
            <a href="https://www.linkedin.com/company/nuna-alma-de-la-energia/" target="_blank">
              <img src={require("../imgs/icon-link.png")} className="iconsfooter" alt="logo" />
            </a>
            
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box>
            <div className="modal-content">
              <form onSubmit={handleLogin}>
                <input type="text" name="user_id" placeholder='Correo electrónico' className='inpLogin' onChange={handleFormChange} required />
                <input type="password" name="user_password" placeholder='Contraseña' className='inpLogin' onChange={handleFormChange} required />
                <a className='generic-subtitle-green' href="/home/forgotPassword" target="_blank" rel="noopener" id="forgotPasswordLink">Olvidó su contraseña?</a>
                <br/><br/>
                <button className="buttonSl" style={{width:"100%"}} type="submit"> <b>INGRESAR</b></button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

    </div>      
  );

}
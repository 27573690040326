import * as React from 'react';
import './GraphicsView.css';
import {Button, Grid, Popover, Typography} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import GraphicsPerformance from './charts/GraphicsPerformance';
import GraphicsConsumption from './charts/GraphicsConsumption';
import GraphicsProduction from './charts/GraphicsProduction';

export default class GraphicsView extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {      
      tabType: "1",
      dynamicClass: "question",
      fullOptiosGraph:false,
      anchor:null
    };
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event, newValue) => {       
    this.setState({tabType:newValue});
  };

  openPopover = (event) => {
    this.setState({anchor:event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchor:null});
  };

  render(){
    return (
      <React.Fragment>
        <Grid container alignItems="center">
          <Grid item xs={1} sm={1}>
             <Button onClick={this.openPopover}>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>
              <Popover 
              open={Boolean(this.state.anchor)} 
              anchorEl={this.state.anchor}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}>
                <Typography style={{padding:'5px'}} variant="h6">
                  Históricos de energía, 
                  <br/>
                  comparativa de espectativas
                  <br/>
                   de producción consumo.                
                </Typography>
              </Popover>
            
          </Grid>
          <Grid item xs={3} sm={4} alignItems="center">
          <span className='graph-text'>Balance Energetico</span>
          </Grid>
          <Grid item xs={6} sm={7} alignItems="center">            
            <TabContext value={this.state.tabType}>
              <TabList onChange={this.handleChange}                    
                indicatorColor=""
                aria-label="secondary tabs example">
                <Tab className='graph-text' label="Producción" value="1" />
                <span className="separador-graph">|</span>
                <Tab className='graph-text' label="Consumo" value="2" />
                <span className="separador-graph">|</span>
                <Tab className='graph-text' label="Hist. Consumo" value="3" />
              </TabList>
            </TabContext>
          </Grid>
        </Grid>

        <br></br>
        
        <TabContext value={this.state.tabType}>              
          <TabPanel value="1"><GraphicsProduction dynamicClass={this.state.dynamicClass} fullOptiosGraph={this.state.fullOptiosGraph}/> </TabPanel>
          <TabPanel value="2"><GraphicsConsumption dynamicClass={this.state.dynamicClass} fullOptiosGraph={this.state.fullOptiosGraph}/> </TabPanel>
          <TabPanel value="3"><GraphicsPerformance dynamicClass={this.state.dynamicClass}/> </TabPanel>
        </TabContext>
      </React.Fragment> 
    );
  }
  
}

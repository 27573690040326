import * as React from 'react';
import "./SiteFooter.css";

const Footer = () => {
  return (
    <div className="footer">
      <div style={{width:"30%", display:"flex", justifyContent:"center", alignItems:"center"}}>      
        <img src={require("../imgs/nunah30.png")} className="nunah15" alt="logo" />              
      </div>         
      <div className='infoFooter'>
        <div>
          <span className='footerCTxt'>(506) 2223-2224</span>
          <span className='footerCTxt'>info@nuna.com</span>
          <span className='footerCTxt'> Contáctenos</span>
          <span> Privacidad y datos</span>
        </div>
        <br/>
        <div>
          <span>Ⓒ Copyright. Todos los derechos reservados.</span>
        </div>
      </div>
      <div className='infoFooterImg'>
        <span>Powered by</span><img src={require("../imgs/greenergy_logo.png")} className="footerImgC" alt="logo2" />        
      </div>
    </div>
  );
};

export default Footer;
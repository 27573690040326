import * as React from 'react';

const LandingFooter = () => {
  return (  
    <div>
      <div className="linea"></div>   
      <div className="footer-ext">
        <div className="footer-int">
          <div className="" style={{width:"120px"}}>
            <img src={require("../imgs/nunah30.png")} className="nunah35" alt="logo" />
          </div>
          <div className="divText-top" style={{width:"954px"}}>
          <div className='footer-box'>                
            <h1 className='generic-title-footer'>Contacto</h1>
            <br/>
            <div className='generic-text-center'>
              Correo: <span className='texto-destacado2'>info@nuna.cr</span><br/>
              Teléfono: (506) 2290-7727<br/>
              Whatsapp: (506) 6395-5374
            </div>
            <br/><br/>
            San José, Costa Rica 2024. © Todos los derechos reservados. 
              <span className='texto-destacado2'>
                <a style={{textDecoration:"underline", color:"#5ebdd9"}} href="/home/terminos-y-condiciones" target="_blank" rel="noopener" id="terms">Términos y Condiciones de privacidad</a>               
              </span>
            </div>
            
          </div>
          <div className="" style={{width:"166px"}}>
            <img src={require("../imgs/icon-email.png")} className="iconsfooter" alt="logo" />

            <a href="https://www.linkedin.com/company/nuna-alma-de-la-energia/" target="_blank">
              <img src={require("../imgs/icon-link.png")} className="iconsfooter" alt="logo" />
            </a>
            
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default LandingFooter;
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
  let token= localStorage.getItem("Token");
  let tokenExp= localStorage.getItem("TokenExp");
  let vencimiento = new Date(tokenExp);
  let fechaactual = new Date();

  if(token==null && vencimiento<fechaactual){
    return <Navigate to="/home" />;
  }else{
    return children;
  }
};

export default ProtectedRoute;
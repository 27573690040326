import React, { useEffect, useRef } from 'react';

const LineaDerecha = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
            const canvas = canvasRef.current;
            var ctx = canvas.getContext("2d");

            var lineX = 10;
            var lineX2 = 100;
            var lineY = 60;
            var dashOffset = 0;

            function dibujarLineaDiscontinua() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.setLineDash([5, 5]);
                ctx.strokeStyle = '#363a45';
                ctx.lineDashOffset = dashOffset;
                ctx.beginPath();
                ctx.moveTo(lineX, 2);
                ctx.lineTo(lineX, lineY);
                ctx.lineTo(180, lineY);
                ctx.lineTo(180, lineX2);
                ctx.stroke();
                ctx.setLineDash([]);
            }

            function animar() {
                dibujarLineaDiscontinua();
            }

            setInterval(animar, 55);
    }, []);

    return (
        <div>
        <canvas ref={canvasRef} width="250" height="110"></canvas>
        </div>
    );
};

export default LineaDerecha;
import React, { useState, useEffect  } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../InfoClientePage.css";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Autocomplete} from '@mui/material';
import {MenuItem, Select, FormControlLabel, Checkbox, TextField, FormControl, Button} from '@mui/material';
import "./Users.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function FormUser(){
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  let [id, setId] = useState('');
  let [systemid] = useState(params.userid);
  let [dialogEdit, setDialogEdit] = useState(false);
  let [readonly, setReadonly] = useState(false);
  let [btnText, setBtnText] = useState('Crear'); 
  let [dialogMessageOpen, setDialogMessageOpen] = useState(false); 
  let [message, setMessage] = useState('');
  let [loading, setLoading] = useState(false);
  let [dialogMessageError, setDialogMessageError] = useState(false); 
  let [messageError, setMessageError] = useState('');
  let [sytemList, setSytemList] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [listMaterials, setListMaterials] = useState([]);

  const handleAddToList = () => {
    if (selectedSystem) {
      setListMaterials(prevList => (prevList ? prevList.concat(selectedSystem) : [selectedSystem]));
      setSelectedSystem(null);
    }
  };

  const [userParams, setUserParams] = 
  useState({ 
      nombre: "",
      apellidos: "",
      email: "",
      username: "",
      contact: "",
      password: "",
      isActive: false,
      resetCode: "",
      isTemporary: false,
      temporaryKey: "",
      role: ""
    });

  useEffect(() => {
    limpiarFormulario();
    cargarDatos();    

    let rol= localStorage.getItem("Rol");
    if (rol === 'User' ) {
      setReadonly(true);
    }

  }, []);

  const cargarDatos = () => {
    if(location.pathname !== '/heatmap/newuser'){
      if(systemid !== ''){
        setBtnText('Guardar');
        setDialogEdit(true);
        getSystemInformation(systemid);           
      }      
    }
    else{
      getSystemsSelect();
    }
  };

  const getSystemsSelect = () =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/GetSystemsSelect", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              setSytemList(data.objectResponse);
            }            
          });
  }

  const getSystemInformation = (userId) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: userId
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Users/GetUserInformation", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              setId(data.objectResponse.id);
              setUserParams(data.objectResponse.userRequest);              
              setSytemList(data.objectResponse.documentInfo);
              setListMaterials(data.objectResponse.userRequest.userSystems)
              
              let system = data.objectResponse.documentInfo.find(option => option.systemId === data.objectResponse.userRequest.systemId);
              setSelectedSystem(system); 
            }            
          });
  }

  const limpiarFormulario = () => {
    setUserParams({
        nombre: "",
        apellidos: "",
        email: "",
        username: "",
        contact: "",
        password: "",
        systemId: "",
        isActive: false,
        resetCode: "",
        isTemporary: false,
        temporaryKey: "",
        role: ""
    });
  };

  const handleCloseMessage= () => {
    limpiarFormulario()
    setDialogMessageOpen(false);
    setMessage('');
    navigate("/listUsers");
  };

  const handleCloseMessageError= () => {
    setDialogMessageError(false);
    setMessageError('');
  };

  const handleClose = () => {
    navigate("/listUsers");   
  };

  const handleFormChange = (e) => {
    if(e.target.name === 'isActive')
      setUserParams({ ...userParams, [e.target.name]: e.target.checked });
    else
      setUserParams({ ...userParams, [e.target.name]: e.target.value });
  };

  const handleAutocompleteChange = (event, newValue) => {
    setUserParams({ ...userParams, "systemId" : newValue.systemId });
    let system = sytemList.find(option => option.systemId === newValue.systemId);              
    setSelectedSystem(system);
  };

  const handleSave = event => {
    event.preventDefault();
    if (dialogEdit){
      updateSystem();
    }else{
      createSystem();
    }
  };

  const createSystem = () =>{    

      setLoading(true);

      let user = { ...userParams };
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          Nombre: user.nombre,
          Apellidos: user.apellidos,
          Email: user.email,
          Username: user.email,
          Contact: user.contact,
          Password: "",          
          IsActive: false,
          ResetCode: "",
          IsTemporary: true,
          TemporaryKey: "",
          Role: user.role,
          UserSystems: listMaterials
        })
      };

      var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Users/CreateUserData", requestOptions);      
      fetch(myRequest)
            .then((response) => response.json())
            .then((data) => {
              if(data.successful){
                setDialogMessageOpen(true);
                setMessage('Usuario creado con éxito');
              }else{
                setDialogMessageOpen(true);
                setMessage(data.message);
              }
            });    
  };

  const updateSystem = () =>{      
    let user = { ...userParams };    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const dataSys = {
      Id: id,   
      Username: user.email,
      userRequest: {
        Nombre: user.nombre,
        Apellidos: user.apellidos,
        Email: user.email,
        Username: user.email,
        Contact: user.contact,
        Password: user.password,
        IsActive: user.isActive,
        ResetCode: user.resetCode,
        IsTemporary: user.isTemporary,
        TemporaryKey: user.temporaryKey,
        Role: user.role,
        UserSystems: listMaterials
      },      
      documentInfo: []
    };
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(dataSys)
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Users/UpdateUserData", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.successful){              
              setDialogMessageOpen(true);
              setMessage('Sistema actualizado con éxito');
            }
            
          });
  };

  return (
    <div>      
      <Grid container spacing={2} justify="center">

        <Grid item xs={12} sm={12}>
          <Paper style={{ height: "105px", background: "#363A45" }}>
            <SiteHeader />
          </Paper>
        </Grid>
        <div className="form-ext">
          <h1 style={{marginTop:"30px", color:"white"}}>Información</h1>
          <div className="form-int">
            <Grid item xs={12} sm={12}>
              <form onSubmit={handleSave}>  
                <h4 className="txtLabel">Nombre</h4>
                <FormControl className="w-100 ">
                    <TextField required type="text" name="nombre" onChange={handleFormChange} value={userParams.nombre} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Apellidos</h4>
                <FormControl className="w-100 ">
                    <TextField required type="text" name="apellidos" onChange={handleFormChange} value={userParams.apellidos} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Correo Electrónico</h4>
                <FormControl className="w-100">
                    <TextField required type="email" name="email" onChange={handleFormChange} value={userParams.email} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Contacto</h4>
                <FormControl className="w-100">
                    <TextField required type="text" name="contact" onChange={handleFormChange} value={userParams.contact} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Sistema</h4>
                <FormControl className="w-100">
                <Autocomplete
                  options={sytemList}
                  style={{ width: "350px" }}
                  getOptionLabel={(option) => option.nombre}
                  value={selectedSystem}
                  onChange={handleAutocompleteChange}
                  renderInput={(params) => <TextField {...params} />}
                  filterOptions={(options, state) => {
                      return options.filter(option =>
                          option.nombre.toLowerCase().includes(state.inputValue.toLowerCase())
                      );
                  }}
                />
                
                </FormControl>
                <Button onClick={handleAddToList}>
                  <FontAwesomeIcon icon={solid("plus")} size="xl"/>
                </Button>
                
                <div>
                  <ul>
                  {listMaterials ? (
                      listMaterials.map((system) => (
                        <li key={system.systemId}>{system.nombre}</li>
                      ))
                    ) : null}
                  </ul>
                </div>
                <h4 className="txtLabel">Role</h4>
                <FormControl className="w-100">
                  <Select
                      labelId="demo-simple-select-label"
                      required
                      id="demo-simple-select"
                      name="role"
                      value={userParams.role}
                      label="Rol"
                      disabled={readonly}
                      onChange={handleFormChange}
                      className="inputBox"
                  >
                    <MenuItem value={'admin'}>Administrador</MenuItem>
                    <MenuItem value={'user'}>Usuario</MenuItem>
                  </Select>
                </FormControl>
                <br/>
                <FormControl className="w-100">
                  <FormControlLabel onChange={handleFormChange} name="isActive" value={userParams.isActive} control={<Checkbox checked={userParams.isActive} />} label="Activo" /> 
                </FormControl>
                
                <br/><br/>

                <button className="btnNewUser formUser-btn" type="submit" disabled={loading} >{btnText}</button> 
                <button className="btnNewUser" type="button" onClick={handleClose}>Cancel</button>        
                
              </form>
            </Grid>
          </div>   
        </div> 


        <Grid item xs={12} sm={12}>
          <Paper style={{ height: "85px", background: "#363A45" }} >
            <SiteFooter />
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={dialogMessageOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Información</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMessage} color="primary">
              Aceptar
            </Button>
          </DialogActions>
      </Dialog>

      <Dialog open={dialogMessageError} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Información</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {messageError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageError} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );  
}

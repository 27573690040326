import React, { Component } from 'react'
import './Nuna.css';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GraphicsView from './GraphicsView';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import Poliza from './indicators/Poliza';
import Consumo from './indicators/Consumo';
import ImpactoAmbiental from './indicators/ImpactoAmbiental';
import Ahorro from './indicators/Ahorro';
import Map from './indicators/Map';
import EnergyFlow from './charts/EnergyFlow';
import Generacion from './indicators/Generacion';
import Autosuficiencia from './indicators/Autosuficiencia';

export default class Nuna extends Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
          tabType: 0,
          loading: true,
        };
      };
  
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event, newValue) => {       
    this.setState({tabType:newValue});
  }

  render(){
      return(
          <div>
            <Grid container spacing={'13px'} justify="center">

              {/* Header */}
              <Grid item xs={12} sm={12}>
                <Paper style={{ height: "105px", background: "#363A45" }}>
                  <SiteHeader/>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}></Grid>

              
              <Grid item xs={1} sm={1}></Grid>

              {/* Componente energy flow */}
              <Grid item xs={3} sm={3}>                  
                <EnergyFlow/>                  
              </Grid>

              {/* Componente central */}
              <Grid item container direction="column" xs={5} sm={5} spacing={'16px'}>
                <Grid item >                  
                  <Paper style={{ height: "440px", background: "#363A45" }} >
                    <GraphicsView/>
                  </Paper>
                </Grid>
                
                <Grid item container spacing={'16px'}>
                  <Grid item xs={6}>
                    <Grid item container spacing={'16px'}>
                    <Grid item xs={12}>
                      <Paper style={{ height: "118px", background: "#363A45" }} >
                        <Generacion/>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper style={{ height: "119px", background: "#363A45" }} >
                        <Autosuficiencia></Autosuficiencia>
                      </Paper>
                    </Grid>
                    
                  </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper style={{ height: "253px", background: "#363A45" }} >
                      <Map></Map>
                    </Paper>
                  </Grid>
                  
                </Grid>
              </Grid>

              {/* Indicadores derecha */}
              <Grid item container direction="column" xs={2} sm={2} spacing={'16px'}>
                <Grid item>
                  <Paper style={{ height: "165px", background: "#363A45" }}>
                    <Ahorro></Ahorro>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper style={{ height: "165px", background: "#363A45" }}>
                    <ImpactoAmbiental></ImpactoAmbiental>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper style={{ height: "165px", background: "#363A45" }}>
                    <Consumo></Consumo>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper style={{ height: "166px", background: "#363A45" }}>
                    <Poliza></Poliza>
                  </Paper>
                </Grid>
              </Grid>

              
              <Grid item xs={1} sm={1}></Grid>
              <Grid item xs={12} sm={12}></Grid>

              {/* Footer */}
              <Grid item xs={12} sm={12}>
                <Paper style={{ height: "85px", background: "#363A45" }} >
                  <SiteFooter/>
                </Paper>
              </Grid>
            </Grid>
          </div>
      );
  }

}


import React, { Component } from 'react'
import './ChartsPage.css'
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

import GraphicsPerformance from './charts/GraphicsPerformance';
import GraphicsConsumption from './charts/GraphicsConsumption';
import GraphicsProduction from './charts/GraphicsProduction';
import GraphicsSavings from './charts/GraphicsSavings';
import GraphicsGeneration from './charts/GraphicsGeneration';

class ChartsPage extends Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
          tabType: "1",
          dynamicClass: "question2",
          fullOptiosGraph:true
        };
      };
  
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event, newValue) => {       
    this.setState({tabType:newValue});
  }

  updateClassName = () => {
    const newClassName = 'question2';
    this.setState({dynamicClass:newClassName});
  };

    render(){
      return(
        <div>              
          <SiteHeader/>
          <div className='body2'>
            <div className="container-charts">
              <h1 style={{color:'white'}}>Gráficos</h1>
              <div>
                <TabContext value={this.state.tabType}>
                  <TabList onChange={this.handleChange} 
                    textColor="secondary"
                    indicatorColor=""
                    aria-label="secondary tabs example">
                    <Tab className='graph-text' label="Producción" value="1" />
                    <span className="separador-graph">|</span>
                    <Tab className='graph-text' label="Consumo" value="2" />
                    <span className="separador-graph">|</span>
                    <Tab className='graph-text' label="Hist. Consumo" value="3" />
                    <span className="separador-graph">|</span>
                    <Tab className='graph-text' label="Ahorros" value="4" />
                    <span className="separador-graph">|</span>
                    <Tab className='graph-text' label="Generación" value="5" />
                  </TabList>
                </TabContext>
              </div>

              <TabContext value={this.state.tabType}>              
              <TabPanel value="1"><GraphicsProduction dynamicClass={this.state.dynamicClass} fullOptiosGraph={this.state.fullOptiosGraph}/> </TabPanel>
              <TabPanel value="2"><GraphicsConsumption dynamicClass={this.state.dynamicClass} fullOptiosGraph={this.state.fullOptiosGraph}/> </TabPanel>
              <TabPanel value="3"><GraphicsPerformance dynamicClass={this.state.dynamicClass}/> </TabPanel>
              <TabPanel value="4"><GraphicsSavings dynamicClass={this.state.dynamicClass}/> </TabPanel>
              <TabPanel value="5"><GraphicsGeneration dynamicClass={this.state.dynamicClass}/> </TabPanel>
              </TabContext>
            </div>                    
          </div>   
          <SiteFooter/>
        </div>
      );
    }

}

export default ChartsPage;
import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import InfoClientePage from './InfoClientePage'

class NunaConfigPage extends Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
          tabType: 0
        };
      };
  
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event, newValue) => {       
    this.setState({tabType:newValue});
  }

    render(){
        return(
            <div>
              <Grid container spacing={2} justify="center">

                {/* Header */}
                <Grid item xs={12} sm={12}>
                  <Paper style={{ height: "10vh", background: "#363A45" }}>
                    <SiteHeader/>
                  </Paper>
                </Grid>
                
                <Grid item xs={1} sm={1}></Grid>

                {/* Config Form */}
                <Grid item xs={6} sm={10}>
                  <InfoClientePage/>
                </Grid>           

                <Grid item xs={1} sm={1}></Grid>

                {/* Footer */}
                <Grid item xs={12} sm={12}>
                  <Paper style={{ height: "8vh", background: "#363A45" }} >
                    <SiteFooter/>
                  </Paper>
                </Grid>
              </Grid>
            </div>

       );
    }

}

export default NunaConfigPage;
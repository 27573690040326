import React, { useEffect,useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import {Button, Grid, Paper, Popover, Typography} from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  textAlign: 'center'
}));

function Autosuficiencia () {

  const [anchor, setAnchor] = useState(null);
  const [porcAutoS, setPorcAutoS] = useState(""); 
  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 2000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    setPorcAutoS(localStorage.getItem("PorcAutoS"));
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <React.Fragment>        
      <div>
        <Grid item container spacing={0}>
          <Grid className='central-indicators' item xs={12} sm={12}>
            <Grid item container spacing={2} alignItems="center">
              
              <Grid item xs={3} sm={3}>
              <Button onClick={openPopover}>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>
              <Popover 
                open={Boolean(anchor)} 
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}>
                <Typography style={{padding:'5px'}} variant="h6">
                  Porcentaje de energía consumida <br/>
                  abastecida por el sistema <br/>
                  fotovoltaico en tiempo real.
                </Typography>
              </Popover>
              </Grid>
              <Grid item xs={9} sm={6} >
                <span className='graph-text'>Autosuficiencia</span>
              </Grid>
              <Grid item xs={9} sm={3} >
                <Item sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
                  <IconButton aria-label="Example"> 
                      <FontAwesomeIcon icon={solid("ellipsis-vertical")} inverse />
                  </IconButton>
                </Item>
              </Grid>              
            </Grid>
          </Grid>

          <Grid className='central-indicators' item xs={12} sm={12}>
            <Grid  item container spacing={2}  alignItems="center">             
              <Grid item xs={3} sm={3}>
                <div  style={{paddingLeft:"15px"}}>
                  <Button variant="contained" disabled>
                    <FontAwesomeIcon icon={solid("helmet-safety")} size="xl" style={{color: "#50B09E",}} />
                  </Button>
                </div>                 
              </Grid>
              <Grid item xs={9} sm={9} >
                <h1 className='graph-Body'>{porcAutoS}%</h1>
                <span className='indicator-mess'> de autosuficiencia actual</span>
              </Grid>                
            </Grid>              
          </Grid>

        </Grid>           
      </div>
    </React.Fragment> 
  );  
}

export default Autosuficiencia;
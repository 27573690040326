import React, { useState, useEffect } from "react";
import '../NunaLandingPage.css';
import { Modal, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

export default function Terms(){

  const navigate = useNavigate();
  const handleClose = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loginParams, setLoginParams] = useState({
    user_id: "",
    user_password: ""
  });

  const handleOpenModal = event => {
    event.preventDefault();
    setModalOpen(true);
  };
    
  const handleFormChange = event => {
    let loginParamsNew = { ...loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    setLoginParams(loginParamsNew);
  };

  const getSystemInformation = (systemid) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/GetSystemInformation", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              localStorage.setItem("System_Name", data.objectResponse.documentInfo.nombre);
              localStorage.setItem("System_Mantenimiento", data.objectResponse.documentInfo.planMantenimiento);
              localStorage.setItem("System_Link", data.objectResponse.documentInfo.enlace);
            }            
          });
  }
    
  const handleLogin = (event) => {
    event.preventDefault();
    let user_id = loginParams.user_id;
    let user_password = loginParams.user_password;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
          Username: user_id,
          Password: user_password
          })
    };
    
    var myRequest = new Request( process.env.REACT_APP_API_URL + "/api/Login/Authenticate", requestOptions);
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if (data.objectResponse != null) {
          localStorage.setItem("Email", data.objectResponse.usuario);
          localStorage.setItem("Rol", data.objectResponse.rol);
          localStorage.setItem("Token", data.objectResponse.token);
          localStorage.setItem("TokenExp", data.objectResponse.tokenExp);
          localStorage.setItem("CountAlarm", "0");
          localStorage.setItem("Record_id", " ");

          if(data.objectResponse.rol === 'Admin'){
            navigate("/systemSelector");
          }else{
            localStorage.setItem("System_id", data.objectResponse.systemId);
            getSystemInformation(data.objectResponse.systemId);
            navigate("/dashboard");
          }
        }else{
          console.log("error");
        }
      });

  };
    
  useEffect(() => {
      localStorage.removeItem("System_id");
      localStorage.removeItem("Email");
      localStorage.removeItem("Token");
      localStorage.removeItem("TokenExp");
      localStorage.removeItem("Rol");    
  }, []);

  return(
      
      <div>
        <div className='top-superpuesto' style={{height:"156px"}}>  
          <div className='header-logo'>
          <a href="/home">
              <img src={require("../../imgs/logo-nuna-peq.png")} className="nunahpeq" alt="logo" />
          </a>
            
          </div>
          <div className='header-buttons'>
            <button className="buttonsStyle" onClick={handleOpenModal}>INICIAR SESIÓN</button>
            <input
              className="buttonsStyle"
              type="submit"
              value="SOLICITAR DEMO" />
          </div>
        </div>
        
        <div className="terms-ext-div">
        <div className="terms-div">
            
            <span className="generic-text-just-xl">
                <b>POLÍTICAS EN MATERIA DE RECOPILACIÓN, USO Y DIVULGACIÓN DE DATOS PERSONALES</b> 
            </span> 
        
        <p>
            <b>CONSENTIMIENTO INFORMADO:</b>  Por este medio autorizo a <b>THE GREEN ENERGY</b>
            <b>PROJECT, S.A.</b>, empresa constituida bajo las normas de la República de Costa Rica y
            registrada con cédula de persona jurídica número 3-101-575817 (a futuro y para efectos
            de estos términos y condiciones sea conocido como greenenergy®) opera el sitio web
            www.nuna.cr, para que consigne, almacene y trate los datos de navegación y personales
            en su base de datos de carácter personal; entendiendo de forma libre, expresa y
            voluntaria que:
        </p>
        
        <p>
            Bajo los presentes términos y condiciones, en greenenergy® informamos sobre nuestra
            política de recopilación, uso y divulgación de datos personales, en nuestra página y/o
            redes sociales mencionadas en el párrafo anterior, y de las opciones de las que
            disponemos en relación con esos datos. Entendiéndose que, “datos de uso” son los datos
            recopilados automáticamente, generados por la utilización del servicio o por la propia
            infraestructura de la página web (por ejemplo, la duración de la visita a una página).
        </p>
        
        <p>
        <b>FINALIDAD:</b> Utilizamos sus datos para remitir y brindar información de nuestros bienes y
            servicios, así como para procurar una mejora continua relacionadas con noticias de
            importancia de nuestra empresa, eventos y actividades desarrolladas por
            greenenergy®, comunicaciones que se realizarán por correo electrónico, por vía telefónica
            o mediante mensajes de WhatsApp. Al utilizar estas páginas, usted acepta la recopilación
            y el uso de información de conformidad con esta política. Los métodos de recopilación son
            varios y están abiertos a cambios y están enfocados a recopilar datos básicos de uso y
            cookies.
        </p>
        
        <p>
        <b>NEGATIVA O SUPRESIÓN:</b> Podría dejar de recibir información de relevancia que le puede
            servir para su desarrollo personal o profesional.
        </p>
        
        <p>
        <b>PLAZO DE TRATAMIENTO:</b> El plazo es de manera indefinida, pero el usuario o usuaria
            puede solicitar supresión de su información en el momento que así lo manifieste
            mediante nuestros medios de contacto telefónico (506) 2290-7727 o correo
            info@nunca.cr.
        </p>
        
        <p>
        <b>COOKIES DE NUESTRO SITIO:</b> Nuestro sitio, utiliza cookies que funcionan como
            identificadores únicos que enviamos a su dispositivo, con el fin de que nuestros sistemas
            puedan reconocer su dispositivo y proporcionarle servicios o productos recurrentes, áreas
            de búsqueda preseleccionadas o bien publicidad basada en sus intereses en otros sitios
            web. También utilizamos cookies para otros fines, como, por ejemplo, pero sin limitarse
            a: a)Identificarle en nuestro sitio web, lo que nos permite ofrecerle recomendaciones de
            producto, b) Proveer cotizaciones y mantener una relación cordial con usted, nuestro
            cliente, c) Impedir actividades fraudulentas, d) Mejorar la seguridad. Entendiendo que,
            estos usos son comunes y cumplen con la regulación existente en Costa Rica
            anteriormente indicada.
        </p>
        
        <p>
        <b>DERECHOS:</b> Para ejercer los derechos que según la Ley No. 8968 le asisten (de acceso,
            rectificación, supresión y los demás amparados por dicha normativa) debe dirigir la
            solicitud al correo info@nunca.cr. Usted puede ordenar a su navegador que rechace todas
            las cookies o que le avise cuando se envía una cookie. Sin embargo, si no acepta cookies,
            es posible que no pueda utilizar algunas partes de la página web de greenenergy®
        </p>
        
        <p>
        <b>ACEPTACIÓN:</b> Los usuarios de esta página aceptan que se recopilen y guarden datos
            correspondientes a las conversaciones y demás interacciones que se generen entre el
            usuario y el sitio web. De igual forma los usuarios aceptan que greenenergy® preste estos
            datos a terceros siempre que tenga relación con el objeto de la finalidad del
            procesamiento de datos antes descrito.
        </p>
        
        <p>
            La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método
            de transmisión por Internet o método de almacenamiento electrónico resulta 100%
            seguro. A pesar de que nos esforzamos por utilizar medios comercialmente aceptables
            para proteger sus datos personales, no podemos garantizar su seguridad
            absoluta. greenenergy® podrá actualizar estos términos y condiciones periódicamente sin
            obligación de anunciar estos cambios por otro medio adicional al presente.
        </p>
        
        
        
        
        
        
          
                </div>
        </div>
        


      <div className="linea"></div>
      <div className="footer-ext">
        <div className="footer-int">
          <div className="" style={{width:"120px"}}> <img src={require("../../imgs/logo-nuna-peq.png")} className="nunahpeq" alt="logo" /></div>
          <div className="divText-top" style={{width:"954px"}}>
          <div className='footer-box'>                
            <h1 className='generic-title-footer'>Contacto</h1>
            <br/>
            <div className='generic-text-center'>
              Correo: <span className='texto-destacado2'>info@nuna.cr</span><br/>
              Teléfono: (506) 2290-7727<br/>
              Whatsapp: (506) 6395-5374
            </div>
            <br/><br/>
            San José, Costa Rica 2024. © Todos los derechos reservados. 
            <span className='texto-destacado2'>
                <a style={{textDecoration:"underline", color:"#5ebdd9"}} href="/home/terminos-y-condiciones" target="_blank" rel="noopener" id="terms">Términos y Condiciones de privacidad</a>               
              </span>
            </div>
            
          </div>
          <div className="" style={{width:"166px"}}>
            <img src={require("../../imgs/icon-email.png")} className="iconsfooter" alt="logo" />
            
            <a href="https://www.linkedin.com/company/nuna-alma-de-la-energia/" target="_blank">
              <img src={require("../../imgs/icon-link.png")} className="iconsfooter" alt="logo" />
            </a>
        </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box>
            <div className="modal-content">
              <form onSubmit={handleLogin}>
                <input type="text" name="user_id" placeholder='Correo electrónico' className='inpLogin' onChange={handleFormChange} required />
                <input type="password" name="user_password" placeholder='Contraseña' className='inpLogin' onChange={handleFormChange} required />
                <a className='generic-subtitle-green' href="#" id="forgotPasswordLink">Olvidó su contraseña?</a>
                <br/><br/>
                <button className="buttonSl" style={{width:"100%"}} type="submit"> <b>INGRESAR</b></button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

    </div>      
  );

}
import React, { useState, useEffect } from 'react'
import { GoogleMap, useLoadScript, HeatmapLayer } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 9.939105689012036, 
  lng: -84.10759570824177
};

function HeatmapTest() {

  const [heatmapPoints, setHeatmapPoints] = useState(null);
  const [heatmapPointsError, setHeatmapPointsError] = useState(null);

  useEffect(() => {
    getSystems();
  }, []);

  const getSystems = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({

      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/GetHeatmapData", requestOptions);
    
    fetch(myRequest)
    .then((response) => response.json())
    .then((data) => {    
      const heatmapData = JSON.parse(data.objectResponse.heatmap);
      setHeatmapPoints(heatmapData);
      const heatmapDataError = JSON.parse(data.objectResponse.heatmapAlarm);
      setHeatmapPointsError(heatmapDataError);
    })
    .catch((error) => {
      console.error('Error al obtener los datos del heatmap:', error);
    }); 
  }

  const {isLoaded} = useLoadScript({
    
    googleMapsApiKey: 'AIzaSyDBBUpiDLUiL8b-LSGvX27KFPvuHq2ny4s',
    libraries: ["visualization"],
  })
  
  const [map, setMap] = useState(null)
  
  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(center);    
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  if (!isLoaded) {
    return <div>Loading</div>
  }

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
  };
  
  let heatmapData = null;
  if (heatmapPoints !== null) {
    heatmapData = heatmapPoints.map(coordenadas => {
        if (!isNaN(coordenadas[0]) && !isNaN(coordenadas[1]) && 
            coordenadas[0] !== "" && coordenadas[1] !== "" &&
            coordenadas[0] !== null && coordenadas[1] !== null) {
            return {
                location: new window.google.maps.LatLng(parseFloat(coordenadas[0]), parseFloat(coordenadas[1])),
                weight: 1
            };
        } else {
            return null;
        }
    }).filter(point => point !== null); 
  } else {
      heatmapData = [{ location: new window.google.maps.LatLng(0, 0), weight: 1 }];
  }

  let heatmapDataError = null;
  if (heatmapPointsError !== null) {
    heatmapDataError = heatmapPointsError.map(coordenadas => {
        if (!isNaN(coordenadas[0]) && !isNaN(coordenadas[1]) && 
            coordenadas[0] !== "" && coordenadas[1] !== "" &&
            coordenadas[0] !== null && coordenadas[1] !== null) {
            return {
                location: new window.google.maps.LatLng(parseFloat(coordenadas[0]), parseFloat(coordenadas[1])),
                weight: 2
            };
        } else {
            return null;
        }
    }).filter(point => point !== null);
  } else {
    heatmapDataError = [{ location: new window.google.maps.LatLng(0, 0), weight: 2 }];
  }

  let combinedHeatmapData = [];
  if (heatmapData !== null) {
    combinedHeatmapData = combinedHeatmapData.concat(heatmapData);
  }
  if (heatmapDataError !== null) {
    combinedHeatmapData = combinedHeatmapData.concat(heatmapDataError);
  }

  const gradient = [
    'rgba(255, 0, 0, 0)', // Color transparente
    'rgba(0, 255, 0, 6)', // Color transparente
    'rgba(255, 0, 0, 1)', // Color rojo sólido
  ];

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={8}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}      
    >
       <HeatmapLayer
          data={combinedHeatmapData.map(point => ({
            location: point.location,
            weight: point.weight
          }))}
          options={{
            gradient: gradient,
            radius: 20
          }}
        />

    </GoogleMap>
  );
}

export default HeatmapTest;
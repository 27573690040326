import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';
import "./ListaAlarmas.css";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import * as moment from 'moment';

const MyFunctionalComponent = ({row}) => {
  const handleClick = () => {
    const id = row.alarmId;
    updateAlarma(id);
  };

  const updateAlarma = (id) =>{   
    var systemid = localStorage.getItem("System_id");
    var recordid = localStorage.getItem("Record_id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid,
        IdRecord: recordid,
        IdAlarma: Number(id)
      })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/UpdateAlarmStatusData", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.successful){
          window.location.reload();
        }
      });
  };
 
  return (
    <div>
      <Button  
        variant="contained" 
        color="primary"
        title="Apagar Alarma"
        className="gridButton"
        startIcon={<IconButton className='icongrid' aria-label="Example"> 
          <FontAwesomeIcon icon={solid("bell-slash")} />
        </IconButton>}
        onClick={() =>handleClick()}
        >
      </Button>
    </div>
  );
};

export default class ListaAlarmas extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      dialogEdit:false,
      
      rows:[],
      alertContent:"",
      columns : [
        
        { field: 'stateCode', headerName: 'Código',  minWidth: 50},
        { field: 'text', headerName: 'Descripción',  minWidth: 400 },
        { field: 'logDateTime', headerName: 'Fecha de Activación', minWidth: 170, valueFormatter: params => 
        moment(params?.value).format("DD/MM/YYYY hh:mm A") },
        { field: 'apagadaDateTime', headerName: 'Apagada', minWidth: 170, valueFormatter: params => 
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),  renderCell: (params) => {
          if (params.row.apagadaDateTime === null) {
            return (<p> </p>);
          }
         }},
        { field: 'encendida', headerName: 'Estado',  minWidth: 50, 
        renderCell: (params) => {
        if (params.row.encendida === 1) {
          return (<p>Activa</p>);
        }
        else
        {
          return (<p>Inactiva</p>);
        }
       } },
        { field: 'apagado', headerName: 'Apagado',  minWidth: 80 },
        
        {
          field: "actions",
          headerName: "Apagar",
          sortable: false,
          flex: 1,
          minWidth: 50,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            if (params.row.apagado === "ADMIN" && params.row.encendida === 1) {
              return ( 
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <MyFunctionalComponent row={params.row}/>
                    </div>
              );
            }
           }
        }
      ],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAlarmData();
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  getAlarmData(){    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    this.setState({rows : []});

    const systemid = localStorage.getItem("System_id");
    const rol = localStorage.getItem("Rol");
    const tipoUser = rol==='Admin'? 1 : 2;
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid,
        TipoUsuario: Number(tipoUser)
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/GetAllAlarmByClient", requestOptions);

    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if (data.objectResponse != null) {   

          var miArray = [];

          for (var clave in data.objectResponse.data) {                
            if (data.objectResponse.data.hasOwnProperty(clave)) {                  
              miArray.push(data.objectResponse.data[clave]);
            }
          } 
          
          this.setState({setRows : miArray}); 
          this.setState({gridRows : miArray}); 
          this.setState({rows : miArray});            
        }
        else{
          this.setState({alert: true, alertContent: data.CodError, loading: false});
        }
      });  
  }

  render(){
    return (
      <div style={{paddingLeft:"19%"}}>
        <div style={{paddingLeft:"30%"}}>
        <h1>Lista de Alarmas de {localStorage.getItem("System_Name")}</h1>
        </div>
        
        <Box sx={{ height: 450, width: '95%', padding: 2, color:'white', float: 'left' }}>
          <DataGrid
            getRowId={(row) => row.alarmId} 
            rows={this.state.rows}
            columns={this.state.columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 6,
                },
              },
            }}
            pageSizeOptions={[6]}
            disableRowSelectionOnClick  
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              apagado: false,
            }}
          />
        </Box>
      </div>
    );
  }

}

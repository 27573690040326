import * as React from 'react';
import {Button, Grid, MenuItem,Select} from '@mui/material';
import './Graphics.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ComposedChart,Line,Area,Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend,BarChart,ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import exportFromJSON from 'export-from-json';;

const customStyle = {
  border: '1px solid #5672B3', // Cambia el color del borde aquí
  width: '100%', 
};

export default class GraphicsProduction extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {      
      dataPrueba:[],
      perMonth:[],
      perYear:[],
      dayDataLine:[],
      graphDate: new Date(),

      graphLineArea: false,
      graphBarGroup: "",

      calendarType: 0,
      dayFilter:"",
      monthFilter:"",
      yearFilter:"",
      pvSystemId: localStorage.getItem("System_id"),
      typeGraph:"Line",
      informationType: "Production",
      unitMeasurement: "kW",
      execut:false,

      typeDate: "Month"
    };
  };
  
  formatXAxis = (value) => {
    return value.substring(0, 2);
  };

  CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload; // Obtenemos los datos del punto del gráfico
      if(this.state.calendarType === 0){
        return (
          <div className="custom-tooltip">
            <p>{data.day}</p>
            <hr/>
            <p>Consumido directamente: {data.consumoDirecto} {this.state.unitMeasurement} </p>
            <p>Potencia a la red: {data.potencia} {this.state.unitMeasurement} </p>
            <p>Potencia desde la red: {data.comprada} {this.state.unitMeasurement} </p>
            <p>Consumo: {data.consumo} {this.state.unitMeasurement} </p>
          </div>
        );
      }else{
        return (
          <div className="custom-tooltip">
            <p>{data.day}</p>
            <hr/>
            <p>Energia suministrada a la red: {data.energiaSuministrada} {this.state.unitMeasurement} </p>
            <p>Consumido directamente: {data.consumoDirecto} {this.state.unitMeasurement} </p>
            <hr/>
            <p>Produccion: {data.produccion} {this.state.unitMeasurement} </p>
          </div>
        );
      }
    }
    return null;
  };

  componentDidMount() {
    this._isMounted = true;

    const actualDate = new Date();
    const costaRicaTimezone = "America/Costa_Rica";
    const options = { timeZone: costaRicaTimezone };
    const crDate = new Date(actualDate.toLocaleString("en-US", options));

    const monthIndex = crDate.getMonth();
    const month = monthIndex + 1; 
    const year = crDate.getFullYear();
    const day = crDate.getDate();

    this.setState({ monthFilter:month, yearFilter:year, dayFilter:day});
    if(this.state.dayDataLine == null || this.state.year == null){
      this.getDataByClientPerDate("0",this.state.typeGraph,this.state.pvSystemId,year,month,day);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDataByClientPerDate(p_calendarType,p_viewType,system, p_year = "", p_month = "", p_day = ""){
    
    let structure = "";
    if(p_viewType === "Line" || p_viewType === "Area" ){
      structure = "GetDataByClientPerDateLineRecharts";
    }else if(p_viewType === "Stacked" || p_viewType === "Grouped"){
      structure = "GetDataByClientPerDateBarRecharts";
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        InformationType: this.state.informationType,
        SystemId: system,
        Type: p_calendarType,
        Day: p_day.toString(),
        Month: p_month.toString(),
        Year: p_year.toString()
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/"+structure, requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              var arra = [];
              if(p_viewType === "Line" || p_viewType === "Area" ){
                if(p_calendarType === "0"){
                  arra = data.objectResponse;
                  this.setState({dayDataLine:arra})                  
                }else{
                  arra = data.objectResponse;
                  this.setState({perYear:arra})
                }
                
              }else if(p_viewType === "Stacked" || p_viewType === "Grouped"){
                arra = data.objectResponse;
                this.setState({perYear:arra})
              }
            }
          });
    }

  changeGraphDay(date){
    this.setState({ dayFilter:"", monthFilter:"", yearFilter:"", perYear:[]});

    const day = date.$d.getDate();
    const monthIndex = date.$d.getMonth();
    const month = monthIndex + 1; 
    const year = date.$d.getFullYear();
    if(this.state.calendarType === 0){
      this.setState({ typeGraph:"Line", calendarType:0, dayFilter:day, monthFilter:month, yearFilter:year});
      this.getDataByClientPerDate("0","Line",this.state.pvSystemId,year,month,day);
    }
  }

  changeGraphMonth(date){
    this.setState({ dayFilter:"", monthFilter:"", yearFilter:"", perYear:[]});

    const monthIndex = date.$d.getMonth();
    const month = monthIndex + 1;
    const year = date.$d.getFullYear();
    if(this.state.calendarType === 1){
      this.setState({typeGraph:"Stacked",calendarType:1,monthFilter:month, yearFilter:year});
      this.getDataByClientPerDate("1",this.state.typeGraph,this.state.pvSystemId,year,month);
    }
  }

  changeGraphYear(date){
    this.setState({ dayFilter:"", monthFilter:"", yearFilter:"", perYear:[]});

    const year = date.$d.getFullYear();
    if(this.state.calendarType === 2){
      this.setState({typeGraph:"Stacked",calendarType:2,yearFilter:year});
      this.getDataByClientPerDate("2",this.state.typeGraph,this.state.pvSystemId,year);
    }
  }

  datepickerFormat = () => {
    if (this.state.calendarType === 0) {         
      return ( 
        <DatePicker value={dayjs(this.state.graphDate)} style={customStyle} className="button" format="DD/MM/YYYY" onChange= {(newDate) => this.changeGraphDay(newDate)}/>
      ); 
    }else if(this.state.calendarType === 1){
      return ( 
        <DatePicker value={dayjs(this.state.graphDate)} onChange= {(newDate) => this.changeGraphMonth(newDate)} views={['month', 'year']} />
      ); 
    }else if(this.state.calendarType === 2){
      return ( 
        <DatePicker value={dayjs(this.state.graphDate)} onChange= {(newDate) => this.changeGraphYear(newDate)} views={['year']} />
      ); 
    }
  };

  handleChange = (event, newValue) => {
    if(newValue === 0){
      this.setState({typeGraph:"Line", calendarType:0, unitMeasurement:"kW"});
      this.getDataByClientPerDate("0","Line",this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter,this.state.dayFilter);
    }
    if(newValue === 1){
      this.setState({typeGraph:"Stacked", calendarType:1, unitMeasurement:"kWh"});
      this.getDataByClientPerDate("1","Stacked",this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter);
    }
    if(newValue === 2){
      this.setState({typeGraph:"Stacked", calendarType:2, unitMeasurement:"kWh"});
      this.getDataByClientPerDate("2","Stacked",this.state.pvSystemId,this.state.yearFilter);
    }
    if(newValue === 3){      
      this.setState({typeGraph:"Stacked", calendarType:3, unitMeasurement:"MWh"});
      this.getDataByClientPerDate("3","Stacked",this.state.pvSystemId);
    }
  } 

  optionGraphChange = () => {

    if (this.state.typeGraph === "Line" || this.state.typeGraph === "Area") {         
      return ( 
        <div className={this.props.dynamicClass}>
          <div className="question-container">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={this.state.dayDataLine.data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid vertical={false} horizontal={false} stroke="#f5f5f5" />
                <XAxis dataKey="day" tickFormatter={this.formatXAxis} type='category' interval={11} tick={{ fontSize: 10 }} scale="band" />
                <YAxis unit={" " + this.state.unitMeasurement} />
                
                <Tooltip content={this.CustomTooltip} />
                <Legend />
                <Area type="linear" name="Consumido directamente"  dataKey="consumoDirecto" stackId="1" fill="#50B09E" stroke="#50B09E" />
                <Area type="linear" name="Potencia a la red" dataKey="potencia" stackId="1" fill="#E49A4E" stroke="#E49A4E" />
                <Area type="linear" name="Potencia desde la red" dataKey="comprada" stackId="1" fill="#5672b3" stroke="#5672b3" />
                
                {/* <Area type="linear" name="Consumo" dataKey="consumo" fill="#CF6679" stroke="#CF6679" /> */}
                <Line type="linear" name="Consumo" dataKey="consumo" stroke="#FFFFFF" dot={false}/>
              </ComposedChart> 
            </ResponsiveContainer>
          </div>
        </div>
      ); 
    }else if(this.state.typeGraph === "Stacked" || this.state.typeGraph === "Grouped"){
      return ( 
        <div className={this.props.dynamicClass}>
          <div className="question-container">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={this.state.perYear.rechartsProdBarDesc}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid  vertical={false} horizontal={false} strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis unit={" " + this.state.unitMeasurement}/>
                
                <Tooltip content={this.CustomTooltip} />
                <Legend />
                <Bar name="Energia suministrada a la red" dataKey="energiaSuministrada" stackId="a" fill="#8884d8" />
                <Bar name="Consumido directamente" dataKey="consumoDirecto" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ); 
    }
  };

  handleFormChange = event => {
    let val = event.target.value;

    if(event.target.name === "calendarType"){
      
      if(val === 0){
        this.getDataByClientPerDate(val,"Line", this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter,this.state.dayFilter);
        this.setState({calendarType: val, typeGraph:"Line"});
      }
      if(val === 1){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }
      if(val === 2){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId,this.state.yearFilter);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }
      if(val === 3){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }        
    }

    if(event.target.name === "clientSystemId"){
      this.setState({pvSystemId: val});
      this.getDataByClientPerDate(this.state.typeGraph,val,this.state.yearFilter,this.state.monthFilter);
    }

    if(event.target.name === "typeGraph"){
      if(val === "Area"){
        this.setState({graphLineArea: true, graphBarGroup:""});
      }else if(val === "Grouped"){
        this.setState({graphLineArea: false, graphBarGroup:"grouped"});
      }
      else{
        this.setState({graphLineArea: false, graphBarGroup:""});
      }

      this.setState({typeGraph: val, perYear:[]});
      this.getDataByClientPerDate(val, this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter);
    }   
  };

  downloadData(datase){
    const data = datase.data;
    for (let i = 0; i < data.length; i++) {
      data[i].Hora = data[i].day;      
      data[i].Potencia_a_la_red = data[i].potencia;
      data[i].Consumido_directamente= data[i].consumoDirecto;
      data[i].Consumo = data[i].consumo;
      data[i].Potencia_desde_la_red = data[i].comprada;

      delete data[i].day;
      delete data[i].potencia;
      delete data[i].consumoDirecto;
      delete data[i].consumo;
      delete data[i].comprada;
      delete data[i].produccion;
    }

    const fileName = 'Reporte_Produccion';
    const exportType =  exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType })
  }

  optionsSelectGraph = () => {
    if(!this.props.fullOptiosGraph){
      return(
        <Grid container spacing={2} justify="center">
          <Grid item xs={5} sm={5}>
            <Select
              style={customStyle}
              labelId="calendarType"
              id="calendarType"
              name="calendarType"
              value={this.state.calendarType}
              onChange={this.handleFormChange}
            >
              <MenuItem value={0}>Día</MenuItem>
              <MenuItem value={1}>Mes</MenuItem>
              <MenuItem value={2}>Año</MenuItem>
              <MenuItem value={3}>Total</MenuItem>
            </Select>
                     
          </Grid>
          <Grid item xs={6} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                {this.datepickerFormat()}                
              </DemoContainer>
            </LocalizationProvider>                     
          </Grid>
          {/* <Grid item xs={4} sm={4}>
            <Select
              style={customStyle}
              labelId="typeGraph"
              id="typeGraph"
              name="typeGraph"
              value={this.state.typeGraph}
              label="Vista"
              //className="button"
              onChange={this.handleFormChange}
            >
              <MenuItem value={"Area"}>Area</MenuItem>
              <MenuItem value={"Line"}>Line</MenuItem>
              <MenuItem value={"Stacked"}>Column (stacked)</MenuItem>
              <MenuItem value={"Grouped"}>Column (grouped)</MenuItem>
              <MenuItem value={"Table"}>Table</MenuItem>
            </Select>                     
          </Grid> */}
        </Grid>
      );
    }else{
      return(
        <Grid container spacing={2} justify="center">
          <Grid item xs={3} sm={3}>
            <Select
              style={customStyle}
              labelId="calendarType"
              id="calendarType"
              name="calendarType"
              value={this.state.calendarType}
              onChange={this.handleFormChange}
            >
              <MenuItem value={0}>Día</MenuItem>
              <MenuItem value={1}>Mes</MenuItem>
              <MenuItem value={2}>Año</MenuItem>
              <MenuItem value={3}>Total</MenuItem>
            </Select>
                     
          </Grid>
          <Grid item xs={5} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                {this.datepickerFormat()}                
              </DemoContainer>
            </LocalizationProvider>                     
          </Grid>
          {/* <Grid item xs={4} sm={2}>
            <Select
              style={customStyle}
              labelId="typeGraph"
              id="typeGraph"
              name="typeGraph"
              value={this.state.typeGraph}
              label="Vista"
              //className="button"
              onChange={this.handleFormChange}
            >
              <MenuItem value={"Area"}>Area</MenuItem>
              <MenuItem value={"Line"}>Line</MenuItem>
              <MenuItem value={"Stacked"}>Column (stacked)</MenuItem>
              <MenuItem value={"Grouped"}>Column (grouped)</MenuItem>
              <MenuItem value={"Table"}>Table</MenuItem>
            </Select>                     
          </Grid> */}
          <Grid item xs={3} sm={3}>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Button onClick={() => this.downloadData(this.state.dayDataLine)}>
              <FontAwesomeIcon icon={solid("file-arrow-down")} size="2xl" style={{color: "#50B09E"}} />
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  render(){
    return (
      <React.Fragment>
        
        {this.optionsSelectGraph()}
        <div>  
          {this.optionGraphChange()}  
        </div>

      </React.Fragment> 
    );
  }
  
}

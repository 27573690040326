import * as React from 'react';
import {Button, Grid, Paper, Popover, Typography} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import '../charts/Graphics.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    textAlign: 'center'
  
  }));

export default class Poliza extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {    
      alert:false,
      alertContent:"",
      planState: false,
      planStateContent:"",
      graphBarGroup: "",
      anchor:null
    };
  };

  openPopover = (event) => {
    this.setState({anchor:event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchor:null});
  };
  
  componentDidMount() {
    this._isMounted = true;

    var systemData = localStorage.getItem("System_Mantenimiento");
    const date = new Date(systemData);
    const actualDate = new Date();
    const isValid = actualDate <= date;    

    if(isValid){
      this.setState({planState:true, planStateContent:"Activo"});
    }else{
      this.setState({planState:false, planStateContent:"Inactivo", alert:true, alertContent: "Plan Inactivo"});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render(){
    return (
      <React.Fragment>
        <div className='indicator-poliza'>  
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Grid item container spacing={2} alignItems="center">
                
                <Grid item xs={3} sm={3}>
                <Button onClick={this.openPopover}>
                <IconButton aria-label="Example"> 
                  <FontAwesomeIcon icon={solid("circle-info")} inverse />
                </IconButton>
              </Button>
              <Popover 
              open={Boolean(this.state.anchor)} 
              anchorEl={this.state.anchor}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}>
                <Typography style={{padding:'5px'}} variant="h6">
                  Indica el estatus del plan de <br/>
                  mantenimiento del sistema.
                </Typography>
              </Popover>
                </Grid>
                <Grid item xs={9} sm={6} >
                  <span className='graph-text'>Plan de mantenimiento</span>
                </Grid>
                <Grid item xs={9} sm={3} >
                  <Item sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
                    <IconButton aria-label="Example"> 
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")} inverse />
                    </IconButton>
                  </Item>
                </Grid>
              
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>

              <Grid item container spacing={2} alignItems="center">
              
                <Grid item xs={3} sm={3}>
                  <div style={{paddingLeft:"15px"}}>

                    <Button variant="contained" disabled>
                      <FontAwesomeIcon icon={solid("helmet-safety")} size="xl" style={{color: "#50B09E",}} />
                    </Button>

                  </div>
                  
                </Grid>
                <Grid item xs={9} sm={9} >
                  <h1 className='graph-Body'>{this.state.planStateContent}</h1>
                  <span className='graph-text'> 
                  {this.state.alert? 
                  <FontAwesomeIcon icon={solid("triangle-exclamation")} style={{color: "#CF6679",}} /> : null}
                  </span>
                  <span className='indicator-alert'> {this.state.alertContent}</span>

                  
                  
                  
                </Grid>                
              </Grid>              
            </Grid>
          </Grid>

        </div>
        
      </React.Fragment> 
    );
  }
  
}

import React from "react";
import './FormMessage.css';
import '../NunaLandingPage.css';
import LandingHeaderFijo from "../LandingHeaderFijo";

export default function FormTomaControlMessage(){
  return(
    <div>
      <LandingHeaderFijo/>
      <div className="containerMessage">
        <div className="formMessage">
          <img src={require("../../imgs/nunah30.png")} className="nunah35" alt="logo" />
          <h4>¡Gracias por tu interés!</h4>
          Nos pondremos en contacto de inmediato para enviarte una propuesta de <img src={require("../../imgs/nunah30.png")} className="nunah15" alt="logo" /><br/>
          Así podrás tomar el control de tu consumo energético.
          <div className="footerMessage">
            Correo: <span className='texto-destacado2'>info@nuna.cr</span> | Teléfono: (506) 2290-7727 | Whatsapp: (506) 6395-5374
          </div>          
        </div>
      </div>
    </div>
    
  );
}
import React, { useEffect, useRef } from 'react';

const LineaIzquierda = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
    var ctx = canvas.getContext("2d");

    var lineX = 80; 
    var lineX2 = 240;
    var lineY = 80;
    var dashOffset = 0; 
    var dashSpeed = 1;

    function dibujarLineaDiscontinua() {
        ctx.clearRect(0, 0, canvas.width, canvas.height); 
        ctx.setLineDash([5, 5]);
        ctx.strokeStyle = '#50B09E';
        ctx.lineDashOffset = dashOffset;
        ctx.beginPath();
        ctx.moveTo(lineX2, 2);
        ctx.lineTo(lineX2, 60);
        ctx.lineTo(lineY, 60 );
        ctx.lineTo(lineX, 100);
        ctx.stroke();
        ctx.setLineDash([]);
    }

    function animar() {
        dashOffset -= dashSpeed;
        if (dashOffset < -10) {
            dashOffset = 0;
        }
        dibujarLineaDiscontinua();
    }

    setInterval(animar, 55);

    }, []);

    return (
        <div>
        <canvas ref={canvasRef} width="250" height="110"></canvas>
        </div>
    );
};

export default LineaIzquierda;

import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Nuna from './components/Nuna';
import NunaConfigPage from './components/NunaConfigPage';
import LandingPage from './components/NunaLandingPage';
import ChartsPage from './components/ChartsPage';
import HeadMapPage from "./components/heatmap/HeadMapPage";
import InfoClientePage from "./components/InfoClientePage";
import ProtectedRoute from './components/ProtectedRoute';
import SystemSelector from './components/SystemSelector';
import AlarmaPage from "./components/heatmap/AlarmaPage";
import Terms from './components/Generals/Terms';
import FormDemo from './components/Forms/FormDemo';
import FormDemoMessage from './components/Forms/FormDemoMessage';
import FormTomaControl from './components/Forms/FormTomaControl';
import FormTomaControlMessage from './components/Forms/FormTomaControlMessage';
import FormUser from './components/Forms/FormUser';
import Users from './components/Forms/Users';
import ResetPassword from './components/Generals/ResetPassword';
import ForgotPassword from './components/Generals/ForgotPassword';

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingData: false,
      user:null
    };
  }

  login = () => {
    this.setState({user:{id:1,name:'John'}});
  }

  logout = () => {
    this.setState({user:null});
  }

  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render(){
    return (
      <BrowserRouter>
        <Routes>  
          <Route path="/" element={<LandingPage/> } />
          <Route path="/home" element={<LandingPage/> } />
          <Route path="/home/terminos-y-condiciones" element={<Terms/> } />
          <Route path="/home/demo" element={<FormDemo/> } />
          <Route path="/home/demo/confirm" element={<FormDemoMessage/> } />
          <Route path="/home/control" element={<FormTomaControl/> } />
          <Route path="/home/control/confirm" element={<FormTomaControlMessage/> } />
          <Route path="/home/resetPassword" element={<ResetPassword/> } />
          <Route path="/home/forgotPassword" element={<ForgotPassword/> } />

          {/* <Route path="/landingPage" element={<LandingPage/> } /> */}
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Nuna />
            </ProtectedRoute>} />
          <Route path="/nunaConfig" element={
            <ProtectedRoute>
              <NunaConfigPage />
            </ProtectedRoute>} />
          <Route path="/charts" element={
            <ProtectedRoute>
              <ChartsPage />
            </ProtectedRoute>} /> 
          <Route path="/heatmap" element={
            <ProtectedRoute>
              <HeadMapPage />
            </ProtectedRoute>} />
          <Route path="/heatmap/newsystem" element={
            <ProtectedRoute>
              <InfoClientePage />
            </ProtectedRoute>} />
          <Route path="/heatmap/editsystem/:systemid" element={
            <ProtectedRoute>
              <InfoClientePage />
            </ProtectedRoute>} />   


          <Route path="/listUsers" element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>} />
          <Route path="/heatmap/newuser" element={
            <ProtectedRoute>
              <FormUser />
            </ProtectedRoute>} />
          <Route path="/heatmap/edituser/:userid" element={
            <ProtectedRoute>
              <FormUser />
            </ProtectedRoute>} />         

          <Route path="*" element={<Navigate from="/" to="/home" />} />

          <Route path="/systemSelector" element={<SystemSelector />} />
          <Route path="/alarmList" element={
            <ProtectedRoute>
              <AlarmaPage />
            </ProtectedRoute>} />
          
        </Routes>
      </BrowserRouter>
    );
  }
  
}

export default App;

import React, { useEffect, useRef } from 'react';

const LineaIzquierdaReverse = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        var ctx = canvas.getContext("2d");
    
        var lineX = 75;
        var lineX2 = 240; 
        var lineY = 60;
        var dashOffset = 0; 
        var dashSpeed = 1;
    
        function dibujarLineaDiscontinua() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.setLineDash([5, 5]);
            ctx.strokeStyle = '#50B09E';
            ctx.lineDashOffset = dashOffset; 
            ctx.beginPath();
            ctx.moveTo(lineX, 180);
            ctx.lineTo(lineX, lineY);
            ctx.lineTo(240, lineY);
            ctx.lineTo(lineX2, 2);
            ctx.stroke();
            ctx.setLineDash([]); 
        }
    
        function animar() {
            dashOffset -= dashSpeed;
            if (dashOffset < -10) {
                dashOffset = 0;
            }   
            dibujarLineaDiscontinua();
        }
    
        setInterval(animar, 55);
    }, []);
    
    return (
        <div>
        <canvas ref={canvasRef} width="250" height="110"></canvas>
        </div>
    );
};

export default LineaIzquierdaReverse;
import React, { Component } from 'react';

const customMapStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    }
  ];


class Map extends Component {
  componentDidMount() {
    this.loadMap();
  }

  loadMap = () => {
    if (window.google) {
      this.initMap();
    } else {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDBBUpiDLUiL8b-LSGvX27KFPvuHq2ny4s&callback=initMap';
      script.async = true;
      script.defer = true;
      script.addEventListener('load', this.initMap);
      document.body.appendChild(script);
    }
  };

  initMap = () => {

    let lat = Number(localStorage.getItem("Coord_lat"));
    let lng = Number(localStorage.getItem("Coord_lng"));
    const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: lat, lng: lng }, // Coordenadas de San Francisco (puedes cambiarlas)
      zoom: 15,
      
      gestureHandling: "cooperative",
      zoomControl: false,
      disableDefaultUI: true,
      stylers: false,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [{ visibility: 'off' }],
        },
        
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "transit",
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
    
    new window.google.maps.Marker({
        position: { lat: lat, lng: lng },
        map,
        title: "Hello World!",
      });

    new window.google.maps.StyledMapType(customMapStyles, { name: "Custom Style" });

  };

  render() {
    return (
      <div id="map" style={{ width: '100%', height: '100%' }}></div>
    );
  }
}

export default Map;
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import ListaAlarmas from './ListaAlarmas';

export default function AlarmPage() {
  return (
    <div>
      <Grid container spacing={2} justify="center">

        {/* Header */}
        <Grid item xs={12} sm={12}>
          <Paper style={{ height: "10vh", background: "#363A45" }}>
            <SiteHeader />
          </Paper>
        </Grid>

        <Grid item xs={1} sm={1}></Grid>
        {/* Grid Clientes */}
        <Grid item xs={4} sm={10}>
            <div className="grid-Alarmas">
            <ListaAlarmas />
            </div>
         </Grid>
        <Grid item xs={1} sm={1}></Grid>
        <Grid item xs={12} sm={12}></Grid>


        {/* Footer */}
        <Grid item xs={12} sm={12}>
          <Paper style={{ height: "10vh", background: "#363A45" }}>
            <SiteFooter />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

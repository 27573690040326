import React, { useState, useEffect } from "react";
import "./NewHeader.style.css";
import { Modal, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useNavigate } from "react-router-dom";

export default function LandingHeaderFijo(){

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [loginParams, setLoginParams] = useState({
    user_id: "",
    user_password: ""
  });
  const handleOpenModal = event => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    let user_id = loginParams.user_id;
    let user_password = loginParams.user_password;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
          Username: user_id,
          Password: user_password
          })
    };
    
    var myRequest = new Request( process.env.REACT_APP_API_URL + "/api/Login/Authenticate", requestOptions);
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if (data.objectResponse != null) {
          localStorage.setItem("Email", data.objectResponse.usuario);
          localStorage.setItem("Rol", data.objectResponse.rol);
          localStorage.setItem("Token", data.objectResponse.token);
          localStorage.setItem("TokenExp", data.objectResponse.tokenExp);
          localStorage.setItem("CountAlarm", "0");
          localStorage.setItem("Record_id", " ");

          if(data.objectResponse.rol === 'Admin'){
            navigate("/systemSelector");
          }else{
            localStorage.setItem("System_id", data.objectResponse.systemId);
            getSystemInformation(data.objectResponse.systemId);
            navigate("/dashboard");
          }
        }else{
          console.log("error");
        }
      });
  };

  const getSystemInformation = (systemid) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/GetSystemInformation", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              localStorage.setItem("System_Name", data.objectResponse.documentInfo.nombre);
              localStorage.setItem("System_Mantenimiento", data.objectResponse.documentInfo.planMantenimiento);
              localStorage.setItem("System_Link", data.objectResponse.documentInfo.enlace);
            }            
          });
  }

  const handleFormChange = event => {
    let loginParamsNew = { ...loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    setLoginParams(loginParamsNew);
  };

  return (
    <div style={{height:"200px", width:"100%"}}>
      <div className='top-superpuesto' style={{height:"156px"}}>  
        <div className='header-logo'>
          <a href="/home">
            <img src={require("../imgs/nunah30.png")} className="nunah35" alt="logo" />
              {/* <img src={require("../imgs/logo-nuna-peq.png")} className="nunahpeq" alt="logo" /> */}
          </a>
        </div>
        <div className='header-buttons'>
          <button className="buttonsStyle" onClick={handleOpenModal}>INICIAR SESIÓN</button>
          <input
            className="buttonsStyle"
            type="submit"
            value="SOLICITAR DEMO" />
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box>
            <div className="modal-content">
              <form onSubmit={handleLogin}>
                <input type="text" name="user_id" placeholder='Correo electrónico' className='inpLogin' onChange={handleFormChange} required />
                <input type="password" name="user_password" placeholder='Contraseña' className='inpLogin' onChange={handleFormChange} required />
                <a className='generic-subtitle-green' href="#" id="forgotPasswordLink">Olvidó su contraseña?</a>
                <br/><br/>
                <button className="buttonSl" style={{width:"100%"}} type="submit"> <b>INGRESAR</b></button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};


import React, { useState, useEffect  } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./InfoClientePage.css"; 
import { TextField, FormControl, Button } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import moment from 'moment';
import {MenuItem, Select} from '@mui/material';

export default function InfoClientePage(){
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  let [id, setId] = useState('');
  let [systemid] = useState(params.systemid);
  let [dialogEdit, setDialogEdit] = useState(false);
  let [tabValue, setTabValue] = useState('1');
  let [readonly, setReadonly] = useState(false);
  let [btnText, setBtnText] = useState('Crear'); 
  let [dialogMessageOpen, setDialogMessageOpen] = useState(false); 
  let [message, setMessage] = useState('');
  let [loading, setLoading] = useState(false);
  let [dialogMessageError, setDialogMessageError] = useState(false); 
  let [messageError, setMessageError] = useState('');

  let [rows, setRows] = useState([
    {id: 1,month: 'Enero',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 2,month: 'Febrero',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 3,month: 'Marzo',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 4,month: 'Abril',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 5,month: 'Mayo',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 6,month: 'Junio',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 7,month: 'Julio',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 8,month: 'Agosto',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 9,month: 'Setiembre',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 10,month: 'Octubre',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 11,month: 'Noviembre',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
    {id: 12,month: 'Diciembre',consumo: '',generacion: '',ahorro: '', maxDemandaRed: '', maxDemandaConsumo:'',},
  ]);

  const columns = [
    {field: 'month',headerName: 'Mes',width: 180,editable: true, valueGetter: (params) => params.row.month },
    {field: 'consumo',headerName: 'Consumo Historico',width: 180,editable: true, valueGetter: (params) => params.row.consumo},
    {field: 'generacion',headerName: 'Generacion Teorica',width: 180,editable: true, valueGetter: (params) => params.row.generacion},
    {field: 'ahorro',headerName: 'Ahorro Estimado',width: 180,editable: true, valueGetter: (params) => params.row.ahorro},
  ];

  const [systemsParams, setSystemsParams] = 
    useState({ 
      systemId:'',
      nombre: '',
      contacto:'',
      telefono: '',
      email: '',

      coordenadaLat: '',         
      coordenadaLng:'',         
      distribuidora:'',
      tipoTarifa:'',
      enlace: '',

      fechaIngreso: null,
      perdida: '',
      planMantenimiento: null,
     });

  useEffect(() => {
    limpiarFormulario();
    cargarDatos();
    
    let rol= localStorage.getItem("Rol");
    if (rol === 'User' ) {
      setReadonly(true);
    }

  }, []);

  const cargarDatos = () => {
    if(location.pathname !== '/heatmap/newsystem'){
      if(systemid !== ''){
        setBtnText('Guardar');
        setDialogEdit(true);
        getSystemInformation(systemid);        
      }
    }
  };

  const handleRowUpdate = (params) => {
    const updatedRows = rows.map(row =>
      row.id === params.id ? { ...params } : row
    );

    setRows(updatedRows);
  };

  const getSystemInformation = (systemid) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: systemid
        })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/GetSystemInformation", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              setId(data.objectResponse.id)
              setSystemsParams(data.objectResponse.documentInfo);
              setRows(data.objectResponse.documentInfo.valoresHistoricos)
            }            
          });
  }

  const limpiarFormulario = () => {
    setSystemsParams({
      systemId:'',
      nombre: '',
      contacto:'',
      telefono: '',
      email: '',

      coordenadaLat: '',         
      coordenadaLng:'',          
      distribuidora:'',
      tipoTarifa:'',
      enlace: '',

      fechaIngreso: null,
      perdida: '',
      planMantenimiento: null,
    });
  };

  const validarVaciosFormulario = () => {

    let errors = false
    for (const key in systemsParams) {
      if (!systemsParams[key]) {
        errors = true;
      }
    }

    return errors;    
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const handleCellKeyDown = (params, event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  
  const handleCloseMessage= () => {
    //limpiarFormulario()
    setDialogMessageOpen(false);
    setMessage('');
    //navigate("/dashboard");
  };

  const handleCloseMessageError= () => {
    setDialogMessageError(false);
    setMessageError('');
  };

  const handleChange = (event, newValue) => {       
    setTabValue(newValue);
  };

  const handleClose = () => {
    if (dialogEdit){
      navigate("/dashboard");
    }else{
      navigate("/systemSelector");
    }    
  };

  const handleFormChange = (e) => {
    setSystemsParams({ ...systemsParams, [e.target.name]: e.target.value });
  };

  const handleSave = event => {
    event.preventDefault();
    if (dialogEdit){
      updateSystem();
    }else{
      createSystem();
    }
  };

  const createSystem = () =>{    
    
    let errors = validarVaciosFormulario(); 
    if(errors){
      setMessageError('Campos requeridos sin completar.');
      setDialogMessageError(true);
    }else{

      setLoading(true);

      let system = { ...systemsParams };
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const valoresHistoricos = rows.map(({ id, month, consumo, generacion, ahorro, maxDemandaRed, maxDemandaConsumo }) => ({
        Id: Number(id),
        Month: month,
        Consumo: consumo,
        Generacion: generacion,
        Ahorro: ahorro,
        MaxDemandaRed: maxDemandaRed,
        MaxDemandaConsumo: maxDemandaConsumo
      }));
      
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          SystemId: system.systemId,
          Nombre: system.nombre,
          Contacto:system.contacto,
          Telefono: system.telefono,
          Email: system.email,

          CoordenadaLat: system.coordenadaLat,         
          CoordenadaLng: system.coordenadaLng,         
          Distribuidora: system.distribuidora,
          TipoTarifa: system.tipoTarifa,
          Enlace: system.enlace,

          FechaIngreso: system.fechaIngreso,
          Perdida: Number(system.perdida),
          PlanMantenimiento: system.planMantenimiento,

          ValoresHistoricos: valoresHistoricos,

          Status: system.email,
        })
      };

      var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/CreateSystemData", requestOptions);
      
      fetch(myRequest)
            .then((response) => response.json())
            .then((data) => {
              if(data.successful){
                setDialogMessageOpen(true);
                setMessage('Sistema creado con éxito');
              }else{
                setDialogMessageOpen(true);
                setMessage(data.message);
              }
            });
    }
  };

  const updateSystem = () =>{      
    let system = { ...systemsParams };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const valoresHistoricos = rows.map(({ id, month, consumo, generacion, ahorro, maxDemandaRed, maxDemandaConsumo }) => ({
      Id: Number(id),
      Month: month,
      Consumo: consumo,
      Generacion: generacion,
      Ahorro: ahorro,
      MaxDemandaRed: maxDemandaRed,
      MaxDemandaConsumo: maxDemandaConsumo
    }));

    const dataSys = {
      DocumentInfo: {
          SystemId: system.systemId,
          Nombre: system.nombre,
          Contacto:system.contacto,
          Telefono: system.telefono,
          Email: system.email,
  
          CoordenadaLat: system.coordenadaLat,         
          CoordenadaLng: system.coordenadaLng,        
          Distribuidora: system.distribuidora,
          TipoTarifa: system.tipoTarifa,
          Enlace: system.enlace,
  
          FechaIngreso: system.fechaIngreso,
          Perdida: Number(system.perdida),
          PlanMantenimiento: system.planMantenimiento,

          ValoresHistoricos: valoresHistoricos,
          Status: system.email,
      },
      Id: id,   
      generacion: "",
      ahorro: Number(0),
      alarms: ""
    };
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(dataSys)
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/UpdateSystemData", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.successful){
              localStorage.setItem("System_Name", system.nombre);
              localStorage.setItem("System_Mantenimiento", system.planMantenimiento);
              localStorage.setItem("System_Link", system.enlace);
              setDialogMessageOpen(true);
              setMessage('Sistema actualizado con éxito');
            }
            
          });
  };

  return (
    <div>      
    <Grid container spacing={2} justify="center">

      {/* Header */}
      <Grid item xs={12} sm={12}>
        <Paper style={{ height: "105px", background: "#363A45" }}>
          <SiteHeader />
        </Paper>
      </Grid>

    <Grid item xs={12} sm={12}>
    <div className="info-cliente client-form">
      <form onSubmit={handleSave}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Información Principal" value="1" />
            <Tab label="Información Sistema" value="2" />
            <Tab label="Configuración" value="3" />
            <Tab label="Valores Generales" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"> 
          <div className="contenedorPrincipal">
            <div className="contenedor1">
                <h4 className="txtLabel">System Id</h4>
                <FormControl className="w-100 ">
                    <TextField required type="text" name="systemId" onChange={handleFormChange} value={systemsParams.systemId} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Nombre</h4>
                <FormControl className="w-100 ">
                    <TextField required type="text" name="nombre" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.nombre} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Contacto</h4>
                <FormControl className="w-100">
                    <TextField required type="text" name="contacto" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.contacto} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Teléfono de contacto</h4>
                <FormControl className="w-100">
                    <TextField required type="text" name="telefono" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.telefono} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Correo Electrónico</h4>
                <FormControl className="w-100">
                    <TextField required type="email" name="email" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.email} className="inputBox" disabled={readonly}/>
                </FormControl>
              </div>
          </div>
        </TabPanel>
        <TabPanel value="2"> 
          <div className="contenedorPrincipal">
              <div className="contenedor1">
                <h4 className="txtLabel">Coordenadas Lng</h4>
                <FormControl className="w-100">
                    <TextField required type="text" name="coordenadaLng" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.coordenadaLng} className="inputBox" disabled={readonly}/>
                </FormControl>
                <h4 className="txtLabel">Coordenadas Lat</h4>
                <FormControl className="w-100">
                    <TextField required type="text" name="coordenadaLat" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.coordenadaLat} className="inputBox" disabled={readonly}/>
                </FormControl>                    
                <h4 className="txtLabel">Compañía distribuidora</h4>
                <FormControl className="w-100">
                  <Select
                    labelId="demo-simple-select-label"
                    required
                    id="demo-simple-select"
                    name="distribuidora"
                    value={systemsParams.distribuidora}
                    label="Age"
                    disabled={readonly}
                    onChange={handleFormChange}
                    onKeyDown={handleKeyDown} 
                    className="inputBox"
                  >
                    <MenuItem value={'1'}>Coopelesca</MenuItem>
                    <MenuItem value={'2'}>Coopesantos</MenuItem>
                    <MenuItem value={'3'}>Coopealfaro Ruiz</MenuItem>
                    <MenuItem value={'4'}>Coopeguanacaste</MenuItem>
                    <MenuItem value={'5'}>CNFL</MenuItem>
                    <MenuItem value={'6'}>ICE</MenuItem>
                    <MenuItem value={'7'}>ESPH</MenuItem>
                    <MenuItem value={'8'}>JASEC</MenuItem>
                  </Select>
                </FormControl>
                <h4 className="txtLabel">Tipo de tarifa</h4>
                  <FormControl className="w-100">
                    <Select
                      labelId="demo-simple-select-label"
                      required
                      id="demo-simple-select"
                      name="tipoTarifa"
                      value={systemsParams.tipoTarifa}
                      label="Age"
                      disabled={readonly}
                      onKeyDown={handleKeyDown} 
                      onChange={handleFormChange}
                      className="inputBox"
                    >
                      <MenuItem value={'1'}>T-RE</MenuItem>
                      <MenuItem value={'2'}>T-REH</MenuItem>
                      <MenuItem value={'3'}>TMT</MenuItem>
                      <MenuItem value={'4'}>TMT-b</MenuItem>
                      <MenuItem value={'5'}>TCO</MenuItem>
                      <MenuItem value={'6'}>TPref</MenuItem>
                    </Select>
                  </FormControl>
                  <h4 className="txtLabel">Enlace</h4>
                  <FormControl className="w-100">
                    <TextField  type="text" required name="enlace" onKeyDown={handleKeyDown} onChange={handleFormChange} value={systemsParams.enlace} className="inputBox" disabled={readonly}/>
                  </FormControl>
              </div>
            </div>
        </TabPanel>
        <TabPanel value="3">
          <div className="contenedorPrincipal">
          <div className="contenedor1">
            <h4 className="txtLabel">Fecha de puesta en marcha</h4>
            <FormControl className="w-100">
                <TextField 
                  type="date" 
                  required
                  name="fechaIngreso" 
                  onKeyDown={handleKeyDown} 
                  onChange={handleFormChange}
                  value={moment(systemsParams.fechaIngreso,'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')}
                  className="inputBox" disabled={readonly}/>
            </FormControl>
            <h4 className="txtLabel">Coeficiente de pérdida panel solar</h4>
            <FormControl className="w-100">
                <TextField
                    className="inputBox"
                    name="perdida"
                    onKeyDown={handleKeyDown} 
                    onChange={handleFormChange}
                    required
                    variant="outlined"
                    color="secondary"
                    type="number"
                    defaultValue={0}
                    value={systemsParams.perdida}
                    
                    disabled={readonly}
                    aria-describedby="outlined-weight-helper-text"
                    InputLabelProps={{
                        shrink: true,
                        defaultValue: 0,
                        min: 0
                    }}
                />
            </FormControl>
            <h4 className="txtLabel">Vencimiento de Plan de Mantenimiento</h4>
            <FormControl className="w-100">
                <TextField type="date" 
                  required
                  name="planMantenimiento" 
                  onKeyDown={handleKeyDown}                        
                  onChange={handleFormChange} 
                  value={moment(systemsParams.planMantenimiento,'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')}
                  className="inputBox" disabled={readonly}/>
            </FormControl>
                </div>
            </div>
        </TabPanel>      
        <TabPanel value="4">  

          <Box sx={{ height: 400, width: '40%', padding: 2, color:'white' }}>
            <DataGrid              
              rows={rows}
              columns={columns}
              editMode={"cell"}
              processRowUpdate={handleRowUpdate}
              onCellKeyDown={handleCellKeyDown} 
            />
          </Box>
                          
        </TabPanel>       
       </TabContext>
        <div className="btnForm">
          <Button className="btnGuardar" disabled={loading} variant="contained" color="secondary" type="submit">{btnText}</Button> 
          <Button type="button" onClick={handleClose} className="btnCancel" variant="contained" color="secondary">Cancel</Button>        
        </div>  
      </form>
    </div>

    </Grid>
    <Grid item xs={12} sm={12}>
      <Paper style={{ height: "85px", background: "#363A45" }} >
        <SiteFooter />
      </Paper>
    </Grid>
</Grid>

<Dialog open={dialogMessageOpen} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Información</DialogTitle>
    <DialogContent>
      <DialogContentText>
          {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseMessage} color="primary">
        Aceptar
      </Button>
    </DialogActions>
</Dialog>

<Dialog open={dialogMessageError} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Información</DialogTitle>
    <DialogContent>
      <DialogContentText>
          {messageError}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseMessageError} color="primary">
        Aceptar
      </Button>
    </DialogActions>
</Dialog>

</div>
  );

  
}

import * as React from 'react';
import './Graphics.css';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer 
} from 'recharts';
import exportFromJSON from 'export-from-json'

export default class GraphicsPerformance extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {      
      dataPrueba:[],
      perMonth:[],
      perYear:[],
      dayDataLine:[],
      graphDate: new Date(),

      graphLineArea: false,
      graphBarGroup: "",
      
      performanceCode: 4,
      dayFilter:"",
      monthFilter:"",
      yearFilter:"",
      pvSystemId: localStorage.getItem("System_id"),
      typeGraph:"Line",
      informationType: "Production",
      unitMeasurement: "kwh",
      execut:false,
    };
  };

  CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      return (
        <div className="custom-tooltip">
          <p>{data.day}</p>
          <hr/>
          <p>Consumo Historico: {data.consumoTeorico} {this.state.unitMeasurement} </p>
          <p>Consumo Real: {data.consumoReal} {this.state.unitMeasurement} </p>
          <p>Porcentaje: {data.porcentaje} %</p>
        </div>
      );
    }
  
    return null;
  };

  monthMapping = {
    'Ene.': 'Enero',
    'Feb.': 'Febrero',
    'Mar.': 'Marzo',
    'Abr.': 'Abril',
    'May.': 'Mayo',
    'Jun.': 'Junio',
    'Jul.': 'Julio',
    'Ago.': 'Agosto',
    'Sep.': 'Septiembre',
    'Oct.': 'Octubre',
    'Nov.': 'Noviembre',
    'Dic.': 'Diciembre'
  };

  export(data){
    
    for (let i = 0; i < data.length; i++) {
      data[i].Mes = this.monthMapping[data[i].day];
      
      data[i].Consumo_Real = data[i].consumoReal;
      data[i].Consumo_Teorico = data[i].consumoTeorico;
      data[i].Porcentaje = data[i].porcentaje;

      delete data[i].day;
      delete data[i].consumoReal;
      delete data[i].consumoTeorico;
      delete data[i].porcentaje;
    }

    const fileName = 'Reporte_Historico'
    const exportType =  exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType })
  }


  componentDidMount() {
    this._isMounted = true;
     
    const actualDate = new Date();
    const monthIndex = actualDate.getMonth();
    const month = monthIndex + 1; 
    const year = actualDate.getFullYear();
    const day = actualDate.getDate();

    this.setState({ monthFilter:month, yearFilter:year, dayFilter:day});
    if(this.state.dayDataLine == null || this.state.year == null){
      this.getDataByClientPerDate(this.state.pvSystemId,year,month,day);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDataByClientPerDate(system, p_year = "", p_month = "", p_day = ""){    
    let structure = "GetDataByClientPerformanceRecharts";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: system,
        Type: this.state.performanceCode,
        Day: p_day.toString(),
        Month: p_month.toString(),
        Year: p_year.toString()
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/"+structure, requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              var arra = [];              
              arra = data.objectResponse;
              this.setState({dayDataLine:arra});
            }
          });
    }

  render(){
    return (
      <React.Fragment>
         <div className={this.props.dynamicClass}>
            <div className="question-container">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={this.state.dayDataLine.rechartsPerformance}
                  margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 40,
                  }}
                >
                  <CartesianGrid vertical={false} horizontal={false}  stroke="#f5f5f5" />
                  <XAxis dataKey="day"/>
                  <YAxis yAxisId="left" unit=" kWh"/>
                  <YAxis yAxisId="right" unit="%" orientation="right" />
                  
                  <Tooltip content={this.CustomTooltip} />
                  <Legend />
                  <Bar yAxisId="left" dataKey="consumoReal" name="Consumo Real" barSize={10} fill="#50B09E" />
                  <Bar yAxisId="left" dataKey="consumoTeorico" name="Consumo Historico" barSize={10} fill="#413ea0" />
                  <Line yAxisId="right" type="linear" name="Porcentaje" dataKey="porcentaje" stroke="#fff" dot={true}/>
                
                  {/* <Area type="linear" name="Consumo" dataKey="consumo" fill="#CF6679" stroke="#CF6679" /> */}
                  {/* <Line type="linear" name="Consumo Teorico" dataKey="consumoTeorico" stroke="#CF6679" dot={true}/> */}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
      </React.Fragment> 
    );
  }
  
}

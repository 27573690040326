import * as React from 'react';
import {Button, Grid} from '@mui/material';
import './Graphics.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ResponsiveContainer,ComposedChart } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import exportFromJSON from 'export-from-json';

export default class GraphicsSavings extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {      
      dataPrueba:[],
      perYear:[],
      dayDataLine:[],
      graphDate: new Date(),

      graphLineArea: false,
      graphBarGroup: "",

      calendarType: 0,
      yearFilter:"",
      pvSystemId: localStorage.getItem("System_id"),
      typeGraph:"Line",
      informationType: "Production",
      unitMeasurement: "kW",
      execut:false,

      typeDate: "Year"
    };
  };
  
  formatXAxis = (value) => {
    return value.substring(0, 2);
  };

  CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;     
      return (
        <div className="custom-tooltip">
          <p>{data.month}</p>
          <hr/>
          <p>Ahorro: ₡ {data.saving.toLocaleString()}</p>
          <p>Facturación con PV: ₡ {data.totalPV.toLocaleString()}</p>                    
        </div>
      );      
    }
    return null;
  };

  componentDidMount() {
    this._isMounted = true;
    const actualDate = new Date();
    const year = actualDate.getFullYear();

    this.setState({yearFilter:year});
    if(this.state.dayDataLine == null || this.state.year == null){
      this.getDataByClientPerDate("0",this.state.typeGraph,this.state.pvSystemId,year);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDataByClientPerDate(p_calendarType,p_viewType,system, p_year = ""){
    
    let structure = "GetSavingsGraphicsByClient";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        InformationType: this.state.informationType,
        SystemId: system,
        Type: p_calendarType,
        Year: p_year.toString()
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Fronius/"+structure, requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            if(data.objectResponse != null){
              var arra = [];
              arra = data.objectResponse;
              this.setState({perYear:arra})
              
            }
          });
    }

  changeGraphDay(date){
    this.setState({ dayFilter:"", monthFilter:"", yearFilter:"", perYear:[]});

    const year = date.$d.getFullYear();
    if(this.state.calendarType === 0){
      this.setState({ typeGraph:"Line", calendarType:0, yearFilter:year});
      this.getDataByClientPerDate("0","Line",this.state.pvSystemId,year);
    }
  }

  changeGraphMonth(date){
    this.setState({ yearFilter:"", perYear:[]});

    const year = date.$d.getFullYear();
    if(this.state.calendarType === 1){
      this.setState({typeGraph:"Stacked",calendarType:1, yearFilter:year});
      this.getDataByClientPerDate("1",this.state.typeGraph,this.state.pvSystemId,year);
    }
  }

  changeGraphYear(date){
    this.setState({ yearFilter:"", perYear:[]});

    const year = date.$d.getFullYear();
    if(this.state.calendarType === 2){
      this.setState({typeGraph:"Stacked",calendarType:2,yearFilter:year});
      this.getDataByClientPerDate("2",this.state.typeGraph,this.state.pvSystemId,year);
    }
  }

  datepickerFormat = () => {
    return ( 
      <DatePicker value={dayjs(this.state.graphDate)} onChange= {(newDate) => this.changeGraphYear(newDate)} views={['year']} />
    );    
  };

  optionGraphChange = () => {    
    return ( 
      <div className={this.props.dynamicClass}>
        <div className="question-container">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={this.state.perYear.rechartsSavings}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid  vertical={false} horizontal={false} stroke="#f5f5f5" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={(value) => value.toLocaleString('es-ES')} unit="₡"/>
              
              <Tooltip content={this.CustomTooltip} />
              <Legend />
              <Bar name="Facturación con PV" dataKey="totalPV" stackId="a" barSize={10} fill="#8884d8" />
              <Bar name="Ahorro" dataKey="saving" stackId="a" barSize={10} fill="#82ca9d" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );    
  };

  handleFormChange = event => {
    let val = event.target.value;

    if(event.target.name === "calendarType"){
      
      if(val === 0){
        this.getDataByClientPerDate(val,"Line", this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter,this.state.dayFilter);
        this.setState({calendarType: val, typeGraph:"Line"});
      }
      if(val === 1){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }
      if(val === 2){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId,this.state.yearFilter);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }
      if(val === 3){
        this.getDataByClientPerDate(val,"Stacked",this.state.pvSystemId);
        this.setState({calendarType: val, typeGraph:"Stacked"});
      }        
    }

    if(event.target.name === "clientSystemId"){
      this.setState({pvSystemId: val});
      this.getDataByClientPerDate(this.state.typeGraph,val,this.state.yearFilter,this.state.monthFilter);
    }

    if(event.target.name === "typeGraph"){
      if(val === "Area"){
        this.setState({graphLineArea: true, graphBarGroup:""});
      }else if(val === "Grouped"){
        this.setState({graphLineArea: false, graphBarGroup:"grouped"});
      }
      else{
        this.setState({graphLineArea: false, graphBarGroup:""});
      }

      this.setState({typeGraph: val, perYear:[]});
      this.getDataByClientPerDate(val, this.state.pvSystemId,this.state.yearFilter,this.state.monthFilter);
    }   
  };

  downloadData(datase){
    const data = datase.data;
    for (let i = 0; i < data.length; i++) {
      data[i].Hora = data[i].day;      
      data[i].Potencia_a_la_red = data[i].potencia;
      data[i].Consumido_directamente= data[i].consumoDirecto;
      data[i].Consumo = data[i].consumo;
      data[i].Potencia_desde_la_red = data[i].comprada;

      delete data[i].day;
      delete data[i].potencia;
      delete data[i].consumoDirecto;
      delete data[i].consumo;
      delete data[i].comprada;
      delete data[i].produccion;
    }

    const fileName = 'Reporte_Produccion';
    const exportType =  exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType })
  }

  optionsSelectGraph = () => {    
    return(
      <Grid container spacing={2} justify="center">
        <Grid item xs={3} sm={3}>
                    
        </Grid>
        <Grid item xs={5} sm={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              {this.datepickerFormat()}                
            </DemoContainer>
          </LocalizationProvider>                     
        </Grid>
        <Grid item xs={3} sm={3}>
        </Grid>
        <Grid item xs={1} sm={1}>
          <Button onClick={() => this.downloadData(this.state.dayDataLine)}>
            <FontAwesomeIcon icon={solid("file-arrow-down")} size="2xl" style={{color: "#50B09E"}} />
          </Button>
        </Grid>
      </Grid>
    );    
  }

  render(){
    return (
      <React.Fragment>
        
        {/* {this.optionsSelectGraph()} */}
        <div>  
          {this.optionGraphChange()}  
        </div>

      </React.Fragment> 
    );
  }
  
}

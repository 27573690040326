import * as React from 'react';
import "./NewHeader.style.css";
import { Navbar } from "./NavBar";
import Navicons from './NavIcons';

const SiteHeader = () => {
  return (
    <div className="header">
        <Navbar/>
        <Navicons/>
    </div>
  );
};

export default SiteHeader;

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from '@mui/material/Button';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import CustomDialog from "../Generals/CustomDialog";
import "./Users.css";
import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';

export default function Users(){

  const navigate = useNavigate();
  let [dialogMessage, setDialogMessage] = useState(false); 
  let [message] = useState('Desea eliminar este registro?');
  let [selectedSystem, setSelectedSystem] = useState('');
  let [selectedIdSystem, setSelectedIdSystem] = useState('');  
  let [dialogMessageConfirm, setDialogMessageConfirm] = useState(false); 
  let [messageOkConfirm, setMessageOkConfirm] = useState('');
  let [rows, setRows] = useState([]);
  let [columns] = useState([
      
      { field: 'nombreCompleto', headerName: 'Nombre',  
        valueGetter: (params) => `${params.row.userRequest.nombre} ${params.row.userRequest.apellidos}` , minWidth: 200 
      },
      { field: 'contacto', headerName: 'Contacto',  
        valueGetter: (params) => params.row.userRequest.contact, minWidth: 200 
      },
      { field: 'userRequest.username', headerName: 'Usuario',  
        valueGetter: (params) => params.row.userRequest.username, minWidth: 200 
      },
      { field: 'userRequest.systemId', headerName: 'Sistema',  
        valueGetter: (params) => params.row.userRequest.systemId, minWidth: 200 
      },
      { field: 'userRequest.role', headerName: 'Rol',  
        valueGetter: (params) => params.row.userRequest.role, minWidth: 200 
      },
      { field: 'userRequest.isActive', headerName: 'Estado',  
        valueGetter: (params) => { return params.row.userRequest.isActive ? 'Activo' : 'Inactivo' }
      },
     
       {
        field: "action",
        headerName: "Opciones",
        sortable: false,
        width: 170,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
            <div style={{ cursor: "pointer"}}>
              <button data-index={params.row.id} className="buttonSD"  type="button" onClick={() =>handleClickInactOpen(params.row.id)}>
                <FontAwesomeIcon icon={solid("user-pen")} size="xl"/>
              </button>
            </div>                  
          );
        }
      }
  ]);

  useEffect(() => {
    getSystems();
  }, []);

  const handleClickInactOpen = (id) => {
    navigate(`/heatmap/edituser/${id}`);
  }

  const handleCreateUser = () => {
    navigate("/heatmap/newuser");
  }

  const handleConfirmDelete= () => {
    deleteSystem();
    setDialogMessage(false);
    setSelectedSystem('');
  }

  const handleOkConfirm= () => {
    setDialogMessageConfirm(false);
    setSelectedSystem('');
    setSelectedIdSystem('');
  }

  const deleteSystem = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Id: selectedIdSystem,
        SystemId : selectedSystem
      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Clientes/DeleteSystemById", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.successful){
          setMessageOkConfirm("Sistema eliminado correctamente.");
          setDialogMessageConfirm(true);
          getSystems();

        }else{
          setMessageOkConfirm("Ha ocurrido un error al eliminar el sistema.");
          setDialogMessageConfirm(true);
        }                       
      });  
  }

  const handleCancelDelete= () => {
    setDialogMessage(false);
  }

  const getSystems = () => { 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    setRows([]);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({

      })
    };
    
    var myRequest = new Request(process.env.REACT_APP_API_URL + "/api/Users/GetUsersList", requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        setRows(data.objectResponse); 
      });  
  }

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const handleRowClick = (params) => {
    if (params.field !== 'action') {
      clearActualSystemData();

      const system = params.row.documentInfo.systemId;
      const name = params.row.documentInfo.nombre;
      const plan = params.row.documentInfo.planMantenimiento;
      const enlace = params.row.documentInfo.enlace;
      const lat = params.row.documentInfo.coordenadaLat;
      const lng = params.row.documentInfo.coordenadaLng;
      const status = params.row.documentInfo.status;

      localStorage.setItem("System_id", system);
      localStorage.setItem("System_Name", name);
      localStorage.setItem("System_Mantenimiento", plan);
      localStorage.setItem("System_Link", enlace);
      localStorage.setItem("Coord_lat", lat);
      localStorage.setItem("Coord_lng", lng);
      localStorage.setItem("System_Status", status);

      navigate("/dashboard");
    }
  };

  const clearActualSystemData = () =>{
    localStorage.removeItem("System_id");
    localStorage.removeItem("System_Name");
    localStorage.removeItem("System_Mantenimiento");
  }

  return (
    <div>     
        <SiteHeader />
        <div className="user-ext">
            <h1 style={{marginTop:"30px", color:"white"}}>Usuarios</h1>
            <div className="user-int">
                <button className="btnNewUser" onClick={handleCreateUser}> Nuevo </button>
                <DataGrid
                getRowId={(row) => row.id}
                rows={rows}
                columns={columns} 
                checkboxSelection = {false}
                pageSize={5}
                getRowClassName={getRowClassName}
                onCellClick={handleRowClick}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    quickFilterProps:false,              
                    },
                }}
                disableRowSelectionOnClick
                />
            </div>   
        </div>         
        <SiteFooter />

        <Dialog open={dialogMessage} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Información</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
                <Button onClick={handleConfirmDelete} color="primary">
                  Aceptar
                </Button>
                <Button onClick={handleCancelDelete} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
        </Dialog>

        <CustomDialog
          open={dialogMessageConfirm}
          title="Información"
          message= {messageOkConfirm}
          handleConfirm={handleOkConfirm}
        />
    </div>
  );

}
import * as React from 'react';
import {Button, Grid, Paper, Popover, Typography} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import '../charts/Graphics.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    textAlign: 'center'
  
  }));

export default class ImpactoAmbiental extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {      
      co2Evitado: '',
      graphBarGroup: '',
      anchor:null,
      currentYear:""
    };
  };

  openPopover = (event) => {
    this.setState({anchor:event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchor:null});
  };

  componentDidMount() {
    this._isMounted = true;
    const actualDate = new Date();
    const year = actualDate.getFullYear();

    this.setState({currentYear:year});
    this.getC02Data();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getC02Data(){    
    let structure = "GetC02DataIndicator";
    let system = localStorage.getItem("System_id");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        SystemId: system
      })
    };
    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Fronius/"+structure, requestOptions);
    
    fetch(myRequest)
      .then((response) => response.json())
      .then((data) => {
        if(data.objectResponse != null){
                       
          let co2 = data.objectResponse.co2;
          this.setState({co2Evitado:co2}) 
        }
      });
    }

  render(){
    return (
      <React.Fragment>
        <div className='indicator-poliza'>  
          <Grid item container spacing={2}>
            
            <Grid item xs={12} sm={12}>
              <Grid item container spacing={2} alignItems="center">                
                <Grid item xs={3} sm={3}>
                  <Button onClick={this.openPopover}>
                    <IconButton aria-label="Example"> 
                      <FontAwesomeIcon icon={solid("circle-info")} inverse />
                    </IconButton>
                  </Button>
                  <Popover 
                    open={Boolean(this.state.anchor)} 
                    anchorEl={this.state.anchor}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}>
                    <Typography style={{padding:'5px'}} variant="h6">
                      Equivalencia en cantidad de <br/>
                      árboles sembrados para mitigar <br/>
                      el CO2 evitado para el año en curso.
                    </Typography>
                  </Popover>
                </Grid>
                <Grid item xs={9} sm={6} >
                  <span className='graph-text'>Impacto Ambiental</span>
                </Grid>
                <Grid item xs={9} sm={3} >
                  <Item sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
                    <IconButton aria-label="Example"> 
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")} inverse />
                    </IconButton>
                  </Item>
                </Grid>              
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid item container spacing={2} alignItems="center">              
                <Grid item xs={3} sm={3}>
                  <div style={{paddingLeft:"15px"}}>
                    <Button variant="contained" disabled>                      
                      <FontAwesomeIcon icon={solid("tree")} size="xl" style={{color: "#50B09E",}} />
                    </Button>
                  </div>                  
                </Grid>
                <Grid item xs={9} sm={9} >
                  <h1 className='graph-Body'>{this.state.co2Evitado}</h1>
                  <span className='graph-text'>Arboles sembrados en {this.state.currentYear}</span>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </React.Fragment> 
    );
  }
  
}

import React, { useState } from "react";
import '../NunaLandingPage.css';
import { useNavigate } from "react-router-dom";
import { TextField, FormControl, Checkbox, FormControlLabel, FormGroup, Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import LandingHeaderFijo from "../LandingHeaderFijo";
import LandingFooter from "../LandingFooter";
import CustomDialog from "../Generals/CustomDialog";

export default function FormDemo(){

  const navigate = useNavigate();
  let [dialogMessageConfirm, setDialogMessageConfirm] = useState(false); 
  let [messageOkConfirm, setMessageOkConfirm] = useState('');
  const [demoParams, setDemoParams] = 
  useState({ 
    nombre: '',
    apellidos:'',    
    email: '',
    telefono: '',
    empresa: '',
    tipoUsuario:'',
    tipoUsuarioOtro:'',
    nuna:'',
    nunaOtro:''
   });
   
  const [selectedTipoUsuario, setSelectedTipoUsuario] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
  });

  const [selectedConoceNuna, setSelectedConoceNuna] = useState({
    cn1: false,
    cn2: false,
    cn3: false,
    cn4: false,
    cn5: false,
  });

  const handleFormChange = (e) => {
    setDemoParams({ ...demoParams, [e.target.name]: e.target.value });
  };

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;

    const updatedOptions = { ...selectedTipoUsuario };
    Object.keys(updatedOptions).forEach(option => {
      if (option !== name) {
        updatedOptions[option] = false;
      }
    });

    updatedOptions[name] = checked;

    setSelectedTipoUsuario(updatedOptions);
    setDemoParams({ ...demoParams, tipoUsuario: event.target.id });
  };

  const handleConoceNunaChange = (event) => {
    const { name, checked } = event.target;

    const updatedOptions = { ...selectedConoceNuna };
    Object.keys(updatedOptions).forEach(option => {
      if (option !== name) {
        updatedOptions[option] = false;
      }
    });

    updatedOptions[name] = checked;

    setSelectedConoceNuna(updatedOptions);
    setDemoParams({ ...demoParams, nuna: event.target.id });
  };

  const handleSave = event => {
    event.preventDefault();
    let demo = { ...demoParams };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Nombre: demo.nombre,
        Apellidos: demo.apellidos,
        Email: demo.email,
        Telefono: demo.telefono,
        Empresa: demo.empresa,
        TipoUsuario: demo.tipoUsuario,
        TipoUsuarioOtros: demo.tipoUsuarioOtro,
        ComoConocio: demo.nuna,
        ComoConocioOtros: demo.nunaOtro,
        Asunto: 'Solicitud Demo',
        Cuerpo:'Esto es un correo enviado desde el sistema web, correspondiente a "Solicitar Demo".'
      })
    };

    var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Clientes/SendEmail", requestOptions);
    
    fetch(myRequest)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if(data.successful && data.codError === "00"){
              navigate("/home/demo/confirm");
            }else{
              setMessageOkConfirm("Error en el envío del formulario.");
              setDialogMessageConfirm(true);
            }            
          });
  };

  const handleOkConfirm= () => {
    limpiarFormulario();
    setDialogMessageConfirm(false);
    navigate("/home");
  }

  const limpiarFormulario = () => {
    setDemoParams({
      nombre: '', apellidos:'', email: '', telefono: '', empresa: '', tipoUsuario:'', tipoUsuarioOtro:'', nuna:'', nunaOtro:''
    });

    setSelectedTipoUsuario({
      option1: false, option2: false, option3: false, option4: false, option5: false,
    });

    setSelectedConoceNuna({
      cn1: false, cn2: false, cn3: false, cn4: false, cn5: false,
    });
  };

  return(
    <div>
      <LandingHeaderFijo/>

      <div className="formsGeneral">
        <div className="centeredForm">
          <form onSubmit={handleSave}>          
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>SOLICITAR DEMO</h4>
              </Grid>

              <Grid item xs={4}>
                <h4 className="txtLabel">Nombre:</h4>
              </Grid>
              <Grid item xs={8}>
                <FormControl className="inputTopMar">
                  <TextField required type="text" onChange={handleFormChange} name="nombre" value={demoParams.nombre}  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <h4 className="txtLabel">Apellidos:</h4>
              </Grid>
              <Grid item xs={8}>
                <FormControl className="inputTopMar">
                  <TextField required type="text" onChange={handleFormChange} name="apellidos" value={demoParams.apellidos}  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <h4 className="txtLabel">Correo electrónico:</h4>
              </Grid>
              <Grid item xs={8}>
                <FormControl className="inputTopMar">
                  <TextField required type="text" onChange={handleFormChange} name="email" value={demoParams.email}  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <h4 className="txtLabel">Teléfono :</h4>
              </Grid>
              <Grid item xs={8}>
                <FormControl className="inputTopMar">
                  <TextField required type="text" onChange={handleFormChange} name="telefono" value={demoParams.telefono}  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <h4 className="txtLabel">Nombre de empresa:</h4>
              </Grid>
              <Grid item xs={8}>
                <FormControl className="inputTopMar">
                  <TextField required type="text" onChange={handleFormChange} name="empresa" value={demoParams.empresa}  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <h4 className="txtLabel">Tipo de usuario: Elija la opción que mejor le describe.</h4>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox id={"Usuario final"} checked={selectedTipoUsuario.option1} onChange={handleOptionChange} name="option1" />}
                      label="Usuario final"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Instalador de equipo EPC"} checked={selectedTipoUsuario.option2} onChange={handleOptionChange} name="option2" />}
                      label="Instalador de equipo EPC"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Distribuidora"} checked={selectedTipoUsuario.option3} onChange={handleOptionChange} name="option3" />}
                      label="Distribuidora"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Académico"} checked={selectedTipoUsuario.option4} onChange={handleOptionChange} name="option4" />}
                      label="Académico"
                    />
                    <div>
                      <FormControlLabel
                        control={<Checkbox id={"Otro"} checked={selectedTipoUsuario.option5} onChange={handleOptionChange} name="option5" />}
                        label="Otro:"
                      />

                      <TextField type="text" onChange={handleFormChange} name="tipoUsuarioOtro" value={demoParams.tipoUsuarioOtro}  />
                    </div>
                    
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <h4 className="txtLabel">Cómo conoció NUNA®:</h4>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox id={"Sitio web"} checked={selectedConoceNuna.cn1} onChange={handleConoceNunaChange} name="cn1" />}
                      label="Sitio web"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Redes sociales"} checked={selectedConoceNuna.cn2} onChange={handleConoceNunaChange} name="cn2" />}
                      label="Redes sociales"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Noticias"} checked={selectedConoceNuna.cn3} onChange={handleConoceNunaChange} name="cn3" />}
                      label="Noticias"
                    />
                    <FormControlLabel
                      control={<Checkbox id={"Referencia de alguien"} checked={selectedConoceNuna.cn4} onChange={handleConoceNunaChange} name="cn4" />}
                      label="Referencia de alguien"
                    />
                    <div>
                      <FormControlLabel                      
                        control={<Checkbox id={"Otro"} checked={selectedConoceNuna.cn5} onChange={handleConoceNunaChange} name="cn5" />}
                        label="Otro:"
                      />
                      <TextField  type="text" onChange={handleFormChange} name="nunaOtro" value={demoParams.nunaOtro}/>
                    </div>                    
                  </FormGroup>
                </FormControl>
              </Grid>

              <Button style={{marginTop:"20px", marginLeft:"20px", width:"100px", backgroundColor:"#5ebdd9"}} variant="contained" type="submit">Enviar</Button> 
            </Grid>
          </form>
        </div>
      </div>
      <LandingFooter/>

      <CustomDialog
          open={dialogMessageConfirm}
          title="Información"
          message= {messageOkConfirm}
          handleConfirm={handleOkConfirm}
        />
    </div>         
  );
}
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, FormControl } from "@mui/material";
import CustomDialog from "./CustomDialog";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import "./ResetPassword.css";

export default function ResetPassword(){
  const navigate = useNavigate();
  let [dialogMessageConfirm, setDialogMessageConfirm] = useState(false); 
  let [messageOkConfirm, setMessageOkConfirm] = useState('');
  let [dialogMessageCancel, setDialogMessageCancel] = useState(false); 
  let [messageOkCancel, setMessageOkCancel] = useState('');

  const [showCode, setShowCode] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const handleClickShowCode = () => setShowCode((show) => !show);
  const handleClickShowNewPass = () => setShowNewPass((show) => !show);
  const handleClickShowConfirmPass = () => setShowConfirmPass((show) => !show);
  
  const [resetParams, setResetParams] = useState({
    email: "",
    code: "",
    newPass: "",
    confirmPass: ""
  });
    
  const handleClose = () => {
    navigate("/home");   
  };

  const handleFormChange = event => {
    let resetParamsNew = { ...resetParams };
    setResetParams({ ...resetParamsNew, [event.target.name]: event.target.value });
  };

  const handleOkCancel= () => {
    setDialogMessageCancel(false);
  }

  const handleOkConfirm= () => {
    setDialogMessageConfirm(false);
    navigate("/home");
  }

  const handleChangePassword = event =>{
    event.preventDefault();
    let reset = { ...resetParams }; 

    if(reset.newPass === reset.confirmPass){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          TypeReset: 1,
          Email: reset.email,
          Code: reset.code,
          NewPass: reset.newPass,
          ConfirmPass: reset.confirmPass
          })
      };

      var myRequest = new Request(process.env.REACT_APP_API_URL +"/api/Users/ResetPasswordByUser", requestOptions);
      
      fetch(myRequest)
        .then((response) => response.json())
        .then((data) => {
          if(data.successful === true){
            setMessageOkConfirm("Contraseña establecida con éxito.");
            setDialogMessageConfirm(true);
          }            
        });
    }else{
      setMessageOkCancel("Error. Por favor validar los datos ingresados.");
      setDialogMessageCancel(true);
    }    
  }
    
  useEffect(() => {
    
  }, []);

  return(      
    <div>
      <div className="reset-ext">
        <h1 style={{marginTop:"30px", color:"white"}}>Restablecer Contraseña</h1>
        <div className="reset-int" >
          <form onSubmit={handleChangePassword}>
          
            <div className="reset-box">
              <div className="reset-izq">
                <h4 className="txtLabel">Correo Electrónico:</h4>
                <h4 className="txtLabel">Clave temporal:</h4>
                <h4 className="txtLabel">Nueva contraseña:</h4>
                <h4 className="txtLabel">Confirmar contraseña:</h4>
              </div>
              <div className="reset-der">
                <div className="reset-txt">
                  <FormControl>
                    <TextField style={{width:"270px"}} required type="email" onChange={handleFormChange} name="email" value={resetParams.email}  />
                  </FormControl>
                </div>
                
                <div className="reset-txt">
                  <FormControl variant="outlined">
                    <OutlinedInput
                      required                      
                      onChange={handleFormChange} 
                      name="code" 
                      value={resetParams.code}
                      id="outlined-adornment-password"
                      type={showCode ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCode}
                            edge="end"
                          >
                            {showCode ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
                
                <div className="reset-txt">
                  <FormControl variant="outlined">
                    <OutlinedInput
                      required
                      onChange={handleFormChange} 
                      name="newPass" 
                      value={resetParams.newPass}
                      id="outlined-adornment-password"
                      type={showNewPass ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPass}
                            edge="end"
                          >
                            {showNewPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
                
                <div className="reset-txt">
                  <FormControl variant="outlined">
                    <OutlinedInput
                      required
                      onChange={handleFormChange} 
                      name="confirmPass" 
                      value={resetParams.confirmPass}
                      id="outlined-adornment-password"
                      type={showConfirmPass ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPass}
                            edge="end"
                          >
                            {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
                
              </div>
            </div>



              <button className="btnNewUser formUser-btn" type="submit">Guardar</button> 
              <button className="btnNewUser" type="button" onClick={handleClose}>Cancelar</button> 
   
          </form>
        </div>
      </div>

        

      <CustomDialog
        open={dialogMessageCancel}
        title="Información"
        message= {messageOkCancel}
        handleConfirm={handleOkCancel}
      />
      <CustomDialog
        open={dialogMessageConfirm}
        title="Información"
        message= {messageOkConfirm}
        handleConfirm={handleOkConfirm}
      />

    </div>      
  );

}